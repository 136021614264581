/**
 * @todo Homologate User firestore properties to camelcase
 */

import { cloneDeep } from 'lodash'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { getApp } from 'firebase/app'
import { setDocFirestore, updateDocFirestore } from '@/services/firebaseWrite'

const region = process.env.REGION || 'us-central1'

// User interface with all the fields expected for a User document in Firestore
export interface User {
  birthdate: string
  created: string
  first_name: string
  last_name: string
  foto: string
  gender: string
  idUser: string
  phone: string
  picture: string
  public_address: string
  scholarity: string
  uid: string
  location: {
    lat: string
    lng: string
    address: string
    custom: string
    street: string
    num_ext: string
    num_int: string
    neighborhood: string
    betweenStreets: string
    references: string
  }
}

// Function to set or update a user's document in Firestore
export const setUserDoc = async ($fire: any, id: string, body: any) => {
  if (body.typeProfile) {
    delete body.typeProfile
  }
  const data = {
    uid: id,
    ...body,
  } as User
  // Validations could be added here

  // Setting the user's document in Firestore
  return await setDocFirestore($fire, `users/${id}`, { ...data }, true)
}

// Function to retrieve a user by their public address
export const getUserByPublicAddress = async (
  $fire: any,
  { publicAddress }: any
) => {
  return await $fire.firestore
    .collection('users')
    .where('public_address', '==', publicAddress)
    .get()
}

// Function to retrieve a user by their CUIT (Unique Taxpayer Identification Code)
export const getUserByCuit = async ($fire: any, cuit: string) => {
  try {
    const querySnapshot = await $fire.firestore
      .collection('users')
      .where('dni', '==', cuit)
      .get()

    const user = querySnapshot.empty ? null : querySnapshot.docs[0].data()
    return { user }
  } catch (error: any) {
    console.error('Error fetching user by CUIT:', error)
    throw error // Handle this error where this function is called
  }
}

// Function to retrieve a user by their CURP (Unique Population Registration Code)
export const getUserByCurp = async ($fire: any, curp: string) => {
  const querySnapshot = await $fire.firestore
    .collection('users')
    .where('curp', '==', curp)
    .get()
  return querySnapshot.empty ? null : querySnapshot.docs[0].data()
}

// Function to retrieve a user by their CURP (Unique Population Registration Code)
export const getCompanyByRFC = async ($fire: any, rfc: string) => {
  const querySnapshot = await $fire.firestore
    .collection('companies')
    .where('rfc', '==', rfc)
    .get()
  return querySnapshot.empty ? null : querySnapshot.docs[0].data()
}

// Function to retrieve a user by their email
export const getUserByEmail = async ($fire: any, email: string) => {
  return await $fire.firestore
    .collection('users')
    .where('email', '==', email)
    .get()
}

// Function to retrieve a user by their ID
export const getUser = async ($fire: any, id: string) => {
  return await $fire.firestore.collection('users').doc(id).get()
}

// Function to retrieve all users
export const getUsers = async ($fire: any) => {
  return await $fire.firestore.collection('users').get()
}

// Function to set the "levelCidi" field for a user in Firestore
export const setUserCidiLevel = async ($fire: any, id: string) => {
  // Updates a user's "levelCidi" to 2 in Firestore
  return await updateDocFirestore($fire, `users/${id}`, { levelCidi: 2 })
}

// Function to update the user's role (e.g., adding as a "communicator")
export const setUserRole = async (
  $fire: any,
  id: string,
  addAsCommunicator: boolean
) => {
  return await updateDocFirestore($fire, `users/${id}`, {
    role: addAsCommunicator ? 'communicator' : '',
  })
}

// Function to check if a public address exists in the 'users' collection
export const publicAddressExist = async ($fire: any, publicAddress: string) => {
  return await $fire.firestore
    .collection('users')
    .where('public_address', '==', publicAddress)
    .get()
    .then((querySnapshot: any) => {
      return !querySnapshot.empty
    })
}

// Only for companies - Function to retrieve a company by ID
export const getCompany = async ($fire: any, id: string) => {
  return await $fire.firestore.collection('companies').doc(id).get()
}

// Function to check if a public address exists in the 'companies' collection
export const publicAddressCompanyExist = async (
  $fire: any,
  publicAddress: string
) => {
  return await $fire.firestore
    .collection('companies')
    .where('public_address', '==', publicAddress)
    .get()
    .then((querySnapshot: any) => {
      return !querySnapshot.empty
    })
}

// Function to update a company's document in Firestore
export const updateCompanyDoc = async (
  $fire: any,
  id: string,
  body: any,
  publicAddress: string
) => {
  if (body.typeProfile) {
    delete body.typeProfile
  }

  // This is to update the localStorage with the new data
  localStorage.setItem('currentProfile', JSON.stringify(body))

  return await updateDocFirestore($fire, `companies/${id}`, {
    public_address: publicAddress,
  })
}

// Function to set a company's document in Firestore
export const setCompanyDoc = async ($fire: any, id: string, body: any) => {
  if (body.typeProfile) {
    delete body.typeProfile
  }
  const dataForFirebase = cloneDeep(body)
  delete dataForFirebase.company
  delete dataForFirebase.originalUser
  const data = {
    uid: id,
    ...dataForFirebase,
  } as User

  // This is to update the localStorage with the new data
  localStorage.setItem('currentProfile', JSON.stringify(body))

  return await setDocFirestore($fire, `companies/${id}`, { ...data }, true)
}

// Function to validate the login by checking if the user exists
export const validateLogin = async ($fire: any, email: string) => {
  if (!email) {
    throw new Error('The function was called with no arguments (no email)')
  }

  try {
    // Check in Firestore if the user exists
    const userRef = $fire.firestore
      .collection('users')
      .where('email', '==', email)
    const querySnapshot = await userRef.get()

    if (!querySnapshot.empty) {
      const firestoreUser = querySnapshot.docs[0].data()

      if (firestoreUser.email === email) {
        return 'isValid' // Valid user
      } else {
        return 'notValid' // User in Auth but doesn't match Firestore
      }
    } else {
      return 'notValid' // User not found in Firestore
    }
  } catch (error) {
    console.error('Error during validateLogin:', error)
    return 'noAction' // General error
  }
}

// Function to update user identifiers (in batches)
export const updateUserIdentifier = async (config: any) => {
  let body = {}
  const functions = getFunctions(getApp(), region)
  const instance = httpsCallable(functions, 'users-update-updateUsersInBatches')
  return await instance({
    ...body,
  })
    .then((response: any) => {
      if (response.data) return response.data
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error('An error occurred')
      throw new Error(error)
    })
}
