import { getAvailableFeatures } from '@/services/Settings'

export const state = () => ({
  availableFeatures: null,
})

export const mutations = {
  SET_AVAILABLE_FEATURES(state, features) {
    state.availableFeatures = features
  },
}

export const actions = {
  async fetchAvailableFeatures({ commit, state }, $fire) {
    if (state.availableFeatures !== null) return

    try {
      const features = await getAvailableFeatures($fire)

      commit('SET_AVAILABLE_FEATURES', features)
    } catch (error) {
      console.error('Error fetching available features:', error) // eslint-disable-line no-console
    }
  },
}

export const getters = {
  availableFeatures(state) {
    return state.availableFeatures
  },
}
