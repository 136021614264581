/* eslint-disable */
import { getUser, getCompany } from '@/services/User'
import { includes, cloneDeep } from 'lodash'
import { getAuth } from 'firebase/auth'
import { getApp } from 'firebase/app'
import { getFunctions, httpsCallable } from 'firebase/functions'

export const state = () => ({
  user: {
    name: '',
    email: '',
    dependency: '',
    public_address: '',
    first_name: '',
    last_name: '',
    last_name2: '',
    birthday: '',
    rfc: '',
    celphone: '',
    telphone: '',
    // MTY fields
    fotografia: '',
    curp: '',
    biografia: '',
    genero: '',
    // ********************************
    // CP is a shared field for all and ViLo
    cp: '',
    // ********************************
    estado: '',
    municipio: '',
    colonia: '',
    // street and num_ext is a shared field for all and ViLo
    calle: '',
    num_ext: '',
    // ********************************
    num_int: '',
    referencias: '',
    taxProfile: '',
    activated: false,
    created: '',
    uid: '',
    levelUpdated: null,
    token: null,
    role: 'user',
    auxRole: 'citizen',
    availableModules: [],
    typeProfile: 'Ciudadano', // Citizen profile by default
    identify: null,
    // ViLo fields
    deptounidad: '',
    dirección: '',
    piso: '',
    sexo: '',
    torre: '',
    localidad: '',
    // ********************************
    levelCidi: 1, // Default level
    usersIHavePermissions: [],
    companiesIHavePermissions: [],
    involvedModules: [],
  },
  currentUrl: '',
})

export const mutations = {
  // Set ID token
  SET_ID_TOKEN(state, idToken) {
    state.idToken = idToken
  },
  // Set User UID
  SET_USER_UID(state, uid) {
    state.userUid = uid
  },
  // Set user information
  SET_USER: (state, user) => {
    if (user.company || user.isProfileChange) {
      const cloneUser = cloneDeep(user)
      delete cloneUser.isProfileChange
      state.user = { ...cloneUser }
    } else {
      state.user.levelCidi = user.levelCidi ? user.levelCidi : 1
      state.user.type = user.type ? user.type : null
      state.user = user === {} ? { ...user } : { ...state.user, ...user }
    }

    // Assign role
    let role = ''
    if (user.role && user.role !== undefined) {
      role = user.role
    } else if (state.user.role || state.user.role === '') {
      role = state.user.role
    }

    let auxRole = ''
    if (user.auxRole && user.auxRole !== undefined) {
      auxRole = user.auxRole
    } else if (state.user.auxRole || state.user.auxRole !== '') {
      auxRole = state.user.auxRole
    } else {
      auxRole = 'citizen'
    }

    state.user.role = role
    state.user.auxRole = auxRole

    // Set user profile type based on role
    state.user.typeProfile =
      state.user.role &&
      includes(
        ['superadmin', 'admin', 'operator', 'editor', 'communicator'],
        state.user.role
      )
        ? 'Funcionario' // Official
        : state.user.auxRole &&
          includes(['officer', 'visualizer'], state.user.auxRole)
        ? 'Funcionario' // Official
        : 'Ciudadano' // Citizen
  },
  // Set Vilo token
  SET_VILO_TOKEN(state, token) {
    state.vilo_token = token
  },
  // Set token
  SET_TOKEN(state, token) {
    state.token = token
  },
  // Set user role
  SET_ROLE(state, role) {
    state.user.role = role
  },
  // Set auxiliary role
  SET_AUXROLE(state, auxRole) {
    state.user.auxRole = auxRole
  },
  // Set user identity
  SET_IDENTIFY(state, identify) {
    state.user.identify = identify
  },
  // Set available modules for user
  SET_AVAILABLEMODULES(state, availableModules) {
    state.user.availableModules = availableModules
  },
  // Logout user and clear all user data
  LOGOUT_USER(state) {
    state.user = {
      name: '',
      email: '',
      public_address: '',
      first_name: '',
      dependency: '',
      last_name: '',
      last_name2: '',
      birthday: '',
      rfc: '',
      celphone: '',
      telphone: '',
      cp: '',
      estado: '',
      municipio: '',
      colonia: '',
      calle: '',
      num_ext: '',
      num_int: '',
      referencias: '',
      taxProfile: '',
      activated: false,
      created: '',
      uid: '',
      levelUpdated: null,
      token: null,
      role: 'user',
      auxRole: 'citizen',
      availableModules: [],
      typeProfile: 'Ciudadano', // Default citizen profile
      deptounidad: '',
      dirección: '',
      piso: '',
      sexo: '',
      fotografia: '',
      curp: '',
      biografia: '',
      genero: '',
      torre: '',
      localidad: '',
      levelCidi: 1, // Default CIDI level
      useOnlyProcedurePost: false,
      usersEmpowered: [],
      identity: null,
    }
  },
}

export const actions = {
  // Handles changes in authentication state
  async on_auth_state_changed_action({ commit }, { authUser }) {
    if (authUser) {
      const tokenResult = await authUser.getIdTokenResult()
      commit('SET_TOKEN', tokenResult.token)
      commit('SET_ROLE', tokenResult.claims.role)

      // Role for new modules
      if (tokenResult.claims.auxRole) {
        commit('SET_AUXROLE', tokenResult.claims.auxRole)
      } else {
        commit('SET_AUXROLE', 'citizen')
      }
      commit('SET_AVAILABLEMODULES', tokenResult.claims.availableModules)
    }
  },
  // Handles token changes for auth state
  async on_id_token_changed_action({ commit }, { authUser }) {
    if (authUser) {
      const tokenResult = await authUser.getIdTokenResult()
      commit('SET_TOKEN', tokenResult.token)
      commit('SET_ROLE', tokenResult.claims.role)
      if (tokenResult.claims.auxRole) {
        commit('SET_AUXROLE', tokenResult.claims.auxRole)
      } else {
        commit('SET_AUXROLE', 'citizen')
      }
      commit('SET_AVAILABLEMODULES', tokenResult.claims.availableModules)
    }
  },
  // Fetch user profile from Firebase
  async fetchUser({ commit }, authData) {
    const region = process.env.REGION || 'us-central1'
    const functions = getFunctions(getApp(), region)
    const addCitizen = httpsCallable(functions, 'users-addCitizen')

    if (authData && authData.authUser) {
      const tokenResult = await authData.authUser.getIdTokenResult()
      commit('SET_ID_TOKEN', tokenResult.token)
      commit('SET_ROLE', tokenResult.claims.role)
      commit('SET_USER_UID', tokenResult.claims.role)

      // Set auxiliary role for the user
      if (tokenResult.claims.auxRole) {
        commit('SET_AUXROLE', tokenResult.claims.auxRole)
      } else {
        await addCitizen({
          uid: authData.authUser.uid,
        }).then(async () => {
          await getAuth().currentUser?.getIdToken(true)
        })
        commit('SET_AUXROLE', 'citizen')
      }
      commit('SET_AVAILABLEMODULES', tokenResult.claims.availableModules)
      this.$fire.appCheck.activate(this.$config.appCheckSiteKey, true)

      const currentProfile = localStorage.getItem('currentProfile')
      let user = null
      if (currentProfile) {
        // If the profile is a user empowered or a company
        const localStorageUser = JSON.parse(currentProfile)
        let userFromDB = null
        if (localStorageUser.company) {
          // If the profile is a company
          userFromDB = await (
            await getCompany(this.$fire, localStorageUser.uid)
          ).data()
        } else {
          // If the profile is a user empowered
          userFromDB = await (
            await getUser(this.$fire, localStorageUser.uid)
          ).data()
        }
        // Update localStorage with database information
        if (userFromDB) {
          user = Object.assign({}, localStorageUser, userFromDB)
          localStorage.setItem('currentProfile', JSON.stringify({ ...user }))
        } else {
          user = Object.assign({}, localStorageUser)
        }
      } else {
        // If the profile is a user in session
        user = await (await getUser(this.$fire, authData.authUser.uid)).data()
      }

      if (user) {
        // It is added so that it always takes the email of the representative who processes the procedure on behalf of the company.
        user.email =
          user.company && user.company === true
            ? user.originalUser.email
            : user.email
        commit('SET_USER', {
          ...user,
        })
        // Identify user in sentry for error tracking
        this.$sentry.setUser({
          id: user.uid,
          email: user.email,
          username: `${user.first_name} ${user.last_name} ${
            user.last_name2 ? user.last_name2 : ''
          }`,
        })
      }
    } else {
      commit('SET_USER', {})
      this.$sentry.setUser(null)
    }
  },
  // Send a code for phone number verification
  sendCode({ dispatch }, dataRecaptcha) {
    return new Promise((resolve, reject) => {
      this.$fire.auth
        .signInWithPhoneNumber(dataRecaptcha.phone, dataRecaptcha.verifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult
          resolve(confirmationResult)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  // Set user data
  setUserData({ commit }, { data }) {
    commit('SET_USER', { ...data })
  },
}

export const getters = {
  // Get user information
  user: (state) => {
    if (state.user) {
      state.user.email =
        state.user.company && state.user.company === true
          ? state.user.originalUser.email
          : state.user.email
    }
    return state.user
  },
  // Get user's public address
  publicAddress: (state) => {
    return state.user.public_address
  },
  // Get user's level CIDI
  getLevelCidi: (state) => {
    return state.user.levelCidi
  },
  // Get user's level updated date
  levelUpdated: (state) => {
    return state.user.levelUpdated
  },
  // Get user's first name
  userName(state) {
    return state.user.first_name
  },
  // Get user's full name
  getFullUserName(state) {
    return `${state.user.first_name} ${state.user.last_name} ${state.user.last_name2}`
  },
  // Check if the user is verified
  isVerified(state) {
    return state.user.activated
  },
  // Check if the user has an allowed role
  isRoleAllowed(state) {
    if (
      state.user &&
      state.user.role &&
      includes(['admin', 'operator', 'editor'], state.user.role)
    ) {
      return true
    }
    return false
  },
  // Check if the user has a role excluding operator
  isRoleWithoutOperator(state) {
    if (
      state.user &&
      state.user.role &&
      includes(['admin', 'editor'], state.user.role)
    ) {
      return true
    }
    return false
  },
  // Check if the user is an operator
  isOperator(state) {
    return state.user && state.user.role && state.user.role === 'operator'
  },
  // Check if the user is an admin
  isAdmin(state) {
    return state.user && state.user.role && state.user.role === 'admin'
  },
  // Check if the user is an editor
  isEditor(state) {
    return state.user && state.user.role && state.user.role === 'editor'
  },
  // Check if the user is a super admin
  isSuperAdmin(state) {
    return state.user && state.user.role && state.user.role === 'superadmin'
  },
  // Get the user's role
  getRole(state) {
    return state.user.role
  },
  // Get the auxiliary role of the user
  getAuxRole(state) {
    return state.user.auxRole
  },
  // Get appointments role if applicable
  getAppointmentsRole(state) {
    const availableModules = state.user.availableModules || []
    const apptModule = availableModules.find(
      (moduleItem) => moduleItem.module === 'appointments'
    )
    if (state.user && state.user.auxRole === 'officer' && apptModule)
      return apptModule.role
    else return null
  },
  // Get payments role if applicable
  getPaymentsRole(state) {
    const availableModules = state.user.availableModules || []
    const paymentsModule = availableModules.find(
      (moduleItem) => moduleItem.module === 'payments'
    )
    if (state.user && state.user.auxRole === 'officer' && paymentsModule)
      return paymentsModule.role
    else return null
  },
  // Get the type mode of the user
  getTypeMode(state) {
    return state.user.getTypeMode
  },
  // Get available modules for the user
  getAvailableModules(state) {
    return state.user.availableModules
  },
  // Get involved modules for the user
  getInvolvedModules(state) {
    return state.user.involvedModules
  },
  // Get user's dependency
  getDependency(state) {
    return state.user.dependency
  },
  // Get user's email
  getUserEmail(state) {
    return state.user.email
  },
  // Get user's UID
  getUserUid(state) {
    return state.user.uid
  },
  // Get the token
  token(state) {
    return state.token
  },
  // Get Vilo token
  getViloToken(state) {
    return state.vilo_token
  },
  // Get the users the current user has permissions over
  usersIHavePermissions(state) {
    return state.user.usersIHavePermissions
      ? state.user.usersIHavePermissions
      : []
  },
  // Get the companies the current user has permissions over
  companiesIHavePermissions(state) {
    return state.user.companiesIHavePermissions
      ? state.user.companiesIHavePermissions
      : []
  },
  // Get permissions for a specific procedure
  getProcedureCustomRolePermissions(state) {
    const availableModules = state.user.availableModules || []
    const proceduresModule = availableModules.find(
      (moduleItem) => moduleItem.submodule === 'procedures'
    )
    // Get permissions for the procedure
    if (
      state.user &&
      ['operator', 'admin', 'editor'].includes(state.user.role) &&
      proceduresModule
    ) {
      const permissions = proceduresModule.permissions
      if (permissions && permissions.length > 0) return permissions
      else return null
    } else {
      return null
    }
  },
  // Get inspectors role if applicable
  getInspectorsRole(state) {
    const availableModules = state.user.availableModules || []
    const inspectorsModule = availableModules.find(
      (moduleItem) => moduleItem.module === 'inspectors'
    )
    if (state.user && state.user.auxRole === 'officer' && inspectorsModule)
      return inspectorsModule.role
    else return null
  },
  // Get preventive delivery role if applicable
  getPrevDeliveryRole(state) {
    const availableModules = state.user.availableModules || []
    const preventiveDeliveryModule = availableModules.find(
      (moduleItem) => moduleItem.module === 'preventiveDelivery'
    )
    if (
      state.user &&
      state.user.auxRole === 'officer' &&
      preventiveDeliveryModule
    )
      return preventiveDeliveryModule.role
    else return null
  },
  // Get user's identify
  getIdentify(state) {
    return state.user.identify
  },
}
