export const PostsPostCard = () => import('../../themes/Andromeda/components/Posts/PostCard.vue' /* webpackChunkName: "components/posts-post-card" */).then(c => wrapFunctional(c.default || c))
export const PublicHeader = () => import('../../themes/Andromeda/components/public/Header.vue' /* webpackChunkName: "components/public-header" */).then(c => wrapFunctional(c.default || c))
export const PublicHomeAlgoliaSearcher = () => import('../../themes/Andromeda/components/public/HomeAlgoliaSearcher.vue' /* webpackChunkName: "components/public-home-algolia-searcher" */).then(c => wrapFunctional(c.default || c))
export const AuthAutenticar = () => import('../../components/Auth/Autenticar.vue' /* webpackChunkName: "components/auth-autenticar" */).then(c => wrapFunctional(c.default || c))
export const AuthButtonAuth = () => import('../../components/Auth/ButtonAuth.vue' /* webpackChunkName: "components/auth-button-auth" */).then(c => wrapFunctional(c.default || c))
export const AuthCreateNewPassword = () => import('../../components/Auth/CreateNewPassword.vue' /* webpackChunkName: "components/auth-create-new-password" */).then(c => wrapFunctional(c.default || c))
export const AuthDownloadWallet = () => import('../../components/Auth/DownloadWallet.vue' /* webpackChunkName: "components/auth-download-wallet" */).then(c => wrapFunctional(c.default || c))
export const AuthFooterRegister = () => import('../../components/Auth/FooterRegister.vue' /* webpackChunkName: "components/auth-footer-register" */).then(c => wrapFunctional(c.default || c))
export const AuthFormForwardPassword = () => import('../../components/Auth/FormForwardPassword.vue' /* webpackChunkName: "components/auth-form-forward-password" */).then(c => wrapFunctional(c.default || c))
export const AuthFormularioExtranjero = () => import('../../components/Auth/FormularioExtranjero.vue' /* webpackChunkName: "components/auth-formulario-extranjero" */).then(c => wrapFunctional(c.default || c))
export const AuthFormularioRegistroStepOne = () => import('../../components/Auth/FormularioRegistroStepOne.vue' /* webpackChunkName: "components/auth-formulario-registro-step-one" */).then(c => wrapFunctional(c.default || c))
export const AuthFormularioRegistroStepThree = () => import('../../components/Auth/FormularioRegistroStepThree.vue' /* webpackChunkName: "components/auth-formulario-registro-step-three" */).then(c => wrapFunctional(c.default || c))
export const AuthFormularioRegistroStepTwo = () => import('../../components/Auth/FormularioRegistroStepTwo.vue' /* webpackChunkName: "components/auth-formulario-registro-step-two" */).then(c => wrapFunctional(c.default || c))
export const AuthLinkWallet = () => import('../../components/Auth/LinkWallet.vue' /* webpackChunkName: "components/auth-link-wallet" */).then(c => wrapFunctional(c.default || c))
export const AuthLogin = () => import('../../components/Auth/Login.vue' /* webpackChunkName: "components/auth-login" */).then(c => wrapFunctional(c.default || c))
export const AuthMiniQrRegister = () => import('../../components/Auth/MiniQrRegister.vue' /* webpackChunkName: "components/auth-mini-qr-register" */).then(c => wrapFunctional(c.default || c))
export const AuthNuevoLeonComponent = () => import('../../components/Auth/NuevoLeonComponent.vue' /* webpackChunkName: "components/auth-nuevo-leon-component" */).then(c => wrapFunctional(c.default || c))
export const AuthQrLoginQuarkID = () => import('../../components/Auth/QrLoginQuarkID.vue' /* webpackChunkName: "components/auth-qr-login-quark-i-d" */).then(c => wrapFunctional(c.default || c))
export const AuthQrRegisterQuarkID = () => import('../../components/Auth/QrRegisterQuarkID.vue' /* webpackChunkName: "components/auth-qr-register-quark-i-d" */).then(c => wrapFunctional(c.default || c))
export const AuthRegister = () => import('../../components/Auth/Register.vue' /* webpackChunkName: "components/auth-register" */).then(c => wrapFunctional(c.default || c))
export const AuthRioNegroRegister = () => import('../../components/Auth/RioNegroRegister.vue' /* webpackChunkName: "components/auth-rio-negro-register" */).then(c => wrapFunctional(c.default || c))
export const CatalogsCatalogTabs = () => import('../../components/Catalogs/CatalogTabs.vue' /* webpackChunkName: "components/catalogs-catalog-tabs" */).then(c => wrapFunctional(c.default || c))
export const CatalogsDataTableCatalogTerm = () => import('../../components/Catalogs/DataTableCatalogTerm.vue' /* webpackChunkName: "components/catalogs-data-table-catalog-term" */).then(c => wrapFunctional(c.default || c))
export const CatalogsFromAddNewTerm = () => import('../../components/Catalogs/FromAddNewTerm.vue' /* webpackChunkName: "components/catalogs-from-add-new-term" */).then(c => wrapFunctional(c.default || c))
export const ChangelogFilters = () => import('../../components/Changelog/Filters.vue' /* webpackChunkName: "components/changelog-filters" */).then(c => wrapFunctional(c.default || c))
export const CompaniesAvatarMenuCompanies = () => import('../../components/Companies/AvatarMenuCompanies.vue' /* webpackChunkName: "components/companies-avatar-menu-companies" */).then(c => wrapFunctional(c.default || c))
export const CompaniesCompanyProfile = () => import('../../components/Companies/CompanyProfile.vue' /* webpackChunkName: "components/companies-company-profile" */).then(c => wrapFunctional(c.default || c))
export const CredentialsManager = () => import('../../components/Credentials/Manager.vue' /* webpackChunkName: "components/credentials-manager" */).then(c => wrapFunctional(c.default || c))
export const DashboardCounterCard = () => import('../../components/Dashboard/CounterCard.vue' /* webpackChunkName: "components/dashboard-counter-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardCounterGroup = () => import('../../components/Dashboard/CounterGroup.vue' /* webpackChunkName: "components/dashboard-counter-group" */).then(c => wrapFunctional(c.default || c))
export const DashboardDependenciesTable = () => import('../../components/Dashboard/DependenciesTable.vue' /* webpackChunkName: "components/dashboard-dependencies-table" */).then(c => wrapFunctional(c.default || c))
export const DashboardDownloadableGraphs = () => import('../../components/Dashboard/DownloadableGraphs.vue' /* webpackChunkName: "components/dashboard-downloadable-graphs" */).then(c => wrapFunctional(c.default || c))
export const DashboardFilters = () => import('../../components/Dashboard/Filters.vue' /* webpackChunkName: "components/dashboard-filters" */).then(c => wrapFunctional(c.default || c))
export const DashboardGraphs = () => import('../../components/Dashboard/Graphs.vue' /* webpackChunkName: "components/dashboard-graphs" */).then(c => wrapFunctional(c.default || c))
export const FinalDocumentAcknowledgments = () => import('../../components/FinalDocument/Acknowledgments.vue' /* webpackChunkName: "components/final-document-acknowledgments" */).then(c => wrapFunctional(c.default || c))
export const FinalDocumentCertPrintVersion = () => import('../../components/FinalDocument/CertPrintVersion.vue' /* webpackChunkName: "components/final-document-cert-print-version" */).then(c => wrapFunctional(c.default || c))
export const FinalDocumentDataDocument = () => import('../../components/FinalDocument/DataDocument.vue' /* webpackChunkName: "components/final-document-data-document" */).then(c => wrapFunctional(c.default || c))
export const FinalDocumentDesistDocument = () => import('../../components/FinalDocument/DesistDocument.vue' /* webpackChunkName: "components/final-document-desist-document" */).then(c => wrapFunctional(c.default || c))
export const InboxCalendar = () => import('../../components/Inbox/Calendar.vue' /* webpackChunkName: "components/inbox-calendar" */).then(c => wrapFunctional(c.default || c))
export const InboxCounters = () => import('../../components/Inbox/Counters.vue' /* webpackChunkName: "components/inbox-counters" */).then(c => wrapFunctional(c.default || c))
export const InboxDataCard = () => import('../../components/Inbox/DataCard.vue' /* webpackChunkName: "components/inbox-data-card" */).then(c => wrapFunctional(c.default || c))
export const InboxFilters = () => import('../../components/Inbox/Filters.vue' /* webpackChunkName: "components/inbox-filters" */).then(c => wrapFunctional(c.default || c))
export const InboxHistoryTimeline = () => import('../../components/Inbox/HistoryTimeline.vue' /* webpackChunkName: "components/inbox-history-timeline" */).then(c => wrapFunctional(c.default || c))
export const InboxSidebarInbox = () => import('../../components/Inbox/SidebarInbox.vue' /* webpackChunkName: "components/inbox-sidebar-inbox" */).then(c => wrapFunctional(c.default || c))
export const Login = () => import('../../components/Login/Login.vue' /* webpackChunkName: "components/login" */).then(c => wrapFunctional(c.default || c))
export const MapAddress = () => import('../../components/Map/Address.vue' /* webpackChunkName: "components/map-address" */).then(c => wrapFunctional(c.default || c))
export const MapLocationMap = () => import('../../components/Map/LocationMap.vue' /* webpackChunkName: "components/map-location-map" */).then(c => wrapFunctional(c.default || c))
export const Map = () => import('../../components/Map/Map.vue' /* webpackChunkName: "components/map" */).then(c => wrapFunctional(c.default || c))
export const PostsContentFilters = () => import('../../components/Posts/ContentFilters.vue' /* webpackChunkName: "components/posts-content-filters" */).then(c => wrapFunctional(c.default || c))
export const PostsListPostSidebar = () => import('../../components/Posts/ListPostSidebar.vue' /* webpackChunkName: "components/posts-list-post-sidebar" */).then(c => wrapFunctional(c.default || c))
export const PostsPostHeadbar = () => import('../../components/Posts/PostHeadbar.vue' /* webpackChunkName: "components/posts-post-headbar" */).then(c => wrapFunctional(c.default || c))
export const PostsPostSidebar = () => import('../../components/Posts/PostSidebar.vue' /* webpackChunkName: "components/posts-post-sidebar" */).then(c => wrapFunctional(c.default || c))
export const ProcedureStructureChangeQuestionDialog = () => import('../../components/ProcedureStructure/ChangeQuestionDialog.vue' /* webpackChunkName: "components/procedure-structure-change-question-dialog" */).then(c => wrapFunctional(c.default || c))
export const ProcedureStructureConfirmationsDialog = () => import('../../components/ProcedureStructure/ConfirmationsDialog.vue' /* webpackChunkName: "components/procedure-structure-confirmations-dialog" */).then(c => wrapFunctional(c.default || c))
export const ProcedureStructureFeaturedFieldsTemplate = () => import('../../components/ProcedureStructure/FeaturedFieldsTemplate.vue' /* webpackChunkName: "components/procedure-structure-featured-fields-template" */).then(c => wrapFunctional(c.default || c))
export const ProcedureStructure = () => import('../../components/ProcedureStructure/ProcedureStructure.vue' /* webpackChunkName: "components/procedure-structure" */).then(c => wrapFunctional(c.default || c))
export const ProcedureStructureQuestionsTemplate = () => import('../../components/ProcedureStructure/QuestionsTemplate.vue' /* webpackChunkName: "components/procedure-structure-questions-template" */).then(c => wrapFunctional(c.default || c))
export const ProcedureStructureSidebarFeaturedFields = () => import('../../components/ProcedureStructure/SidebarFeaturedFields.vue' /* webpackChunkName: "components/procedure-structure-sidebar-featured-fields" */).then(c => wrapFunctional(c.default || c))
export const ProcedureStructureSidebarQuestions = () => import('../../components/ProcedureStructure/SidebarQuestions.vue' /* webpackChunkName: "components/procedure-structure-sidebar-questions" */).then(c => wrapFunctional(c.default || c))
export const ProfileChangeEmail = () => import('../../components/Profile/ChangeEmail.vue' /* webpackChunkName: "components/profile-change-email" */).then(c => wrapFunctional(c.default || c))
export const ProfileChangePassword = () => import('../../components/Profile/ChangePassword.vue' /* webpackChunkName: "components/profile-change-password" */).then(c => wrapFunctional(c.default || c))
export const RegisterArgRegister = () => import('../../components/Register/ArgRegister.vue' /* webpackChunkName: "components/register-arg-register" */).then(c => wrapFunctional(c.default || c))
export const RegisterAutenticarRegister = () => import('../../components/Register/AutenticarRegister.vue' /* webpackChunkName: "components/register-autenticar-register" */).then(c => wrapFunctional(c.default || c))
export const RegisterDownloadWallet = () => import('../../components/Register/DownloadWallet.vue' /* webpackChunkName: "components/register-download-wallet" */).then(c => wrapFunctional(c.default || c))
export const RegisterEmailRegister = () => import('../../components/Register/EmailRegister.vue' /* webpackChunkName: "components/register-email-register" */).then(c => wrapFunctional(c.default || c))
export const RegisterInfoPortadocumento = () => import('../../components/Register/InfoPortadocumento.vue' /* webpackChunkName: "components/register-info-portadocumento" */).then(c => wrapFunctional(c.default || c))
export const RepresentantsDialogRepresentantInDetail = () => import('../../components/Representants/DialogRepresentantInDetail.vue' /* webpackChunkName: "components/representants-dialog-representant-in-detail" */).then(c => wrapFunctional(c.default || c))
export const UIBase64toImage = () => import('../../components/UI/Base64toImage.vue' /* webpackChunkName: "components/u-i-base64to-image" */).then(c => wrapFunctional(c.default || c))
export const UIBreadcrumbs = () => import('../../components/UI/Breadcrumbs.vue' /* webpackChunkName: "components/u-i-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const UIPdfViewer = () => import('../../components/UI/PdfViewer.vue' /* webpackChunkName: "components/u-i-pdf-viewer" */).then(c => wrapFunctional(c.default || c))
export const UISidebarEditor = () => import('../../components/UI/SidebarEditor.vue' /* webpackChunkName: "components/u-i-sidebar-editor" */).then(c => wrapFunctional(c.default || c))
export const UsersDataTableUsers = () => import('../../components/Users/DataTableUsers.vue' /* webpackChunkName: "components/users-data-table-users" */).then(c => wrapFunctional(c.default || c))
export const VerificationHashDialog = () => import('../../components/Verification/HashDialog.vue' /* webpackChunkName: "components/verification-hash-dialog" */).then(c => wrapFunctional(c.default || c))
export const VerificationCodeDialog = () => import('../../components/Verification/VerificationCodeDialog.vue' /* webpackChunkName: "components/verification-code-dialog" */).then(c => wrapFunctional(c.default || c))
export const CitasAppointmentDataCard = () => import('../../components/citas/AppointmentDataCard.vue' /* webpackChunkName: "components/citas-appointment-data-card" */).then(c => wrapFunctional(c.default || c))
export const CitasAppointmentDetails = () => import('../../components/citas/AppointmentDetails.vue' /* webpackChunkName: "components/citas-appointment-details" */).then(c => wrapFunctional(c.default || c))
export const CitasAppointmentsInbox = () => import('../../components/citas/AppointmentsInbox.vue' /* webpackChunkName: "components/citas-appointments-inbox" */).then(c => wrapFunctional(c.default || c))
export const CitasAssignmentDataCard = () => import('../../components/citas/AssignmentDataCard.vue' /* webpackChunkName: "components/citas-assignment-data-card" */).then(c => wrapFunctional(c.default || c))
export const CitasAssignmentInbox = () => import('../../components/citas/AssignmentInbox.vue' /* webpackChunkName: "components/citas-assignment-inbox" */).then(c => wrapFunctional(c.default || c))
export const CitasCalendarData = () => import('../../components/citas/CalendarData.vue' /* webpackChunkName: "components/citas-calendar-data" */).then(c => wrapFunctional(c.default || c))
export const CitasCitizenAppointment = () => import('../../components/citas/CitizenAppointment.vue' /* webpackChunkName: "components/citas-citizen-appointment" */).then(c => wrapFunctional(c.default || c))
export const CitasDashboard = () => import('../../components/citas/Dashboard.vue' /* webpackChunkName: "components/citas-dashboard" */).then(c => wrapFunctional(c.default || c))
export const CitasDeskForm = () => import('../../components/citas/DeskForm.vue' /* webpackChunkName: "components/citas-desk-form" */).then(c => wrapFunctional(c.default || c))
export const CitasDesksList = () => import('../../components/citas/DesksList.vue' /* webpackChunkName: "components/citas-desks-list" */).then(c => wrapFunctional(c.default || c))
export const CitasGeneralDataForm = () => import('../../components/citas/GeneralDataForm.vue' /* webpackChunkName: "components/citas-general-data-form" */).then(c => wrapFunctional(c.default || c))
export const CitasOfficeList = () => import('../../components/citas/OfficeList.vue' /* webpackChunkName: "components/citas-office-list" */).then(c => wrapFunctional(c.default || c))
export const CitasRescheduleAppointment = () => import('../../components/citas/RescheduleAppointment.vue' /* webpackChunkName: "components/citas-reschedule-appointment" */).then(c => wrapFunctional(c.default || c))
export const ExpedienteAcknowledgmentsDocuments = () => import('../../components/expediente/AcknowledgmentsDocuments.vue' /* webpackChunkName: "components/expediente-acknowledgments-documents" */).then(c => wrapFunctional(c.default || c))
export const ExpedienteAppointments = () => import('../../components/expediente/Appointments.vue' /* webpackChunkName: "components/expediente-appointments" */).then(c => wrapFunctional(c.default || c))
export const ExpedienteCertificados = () => import('../../components/expediente/Certificados.vue' /* webpackChunkName: "components/expediente-certificados" */).then(c => wrapFunctional(c.default || c))
export const ExpedienteChangePassword = () => import('../../components/expediente/ChangePassword.vue' /* webpackChunkName: "components/expediente-change-password" */).then(c => wrapFunctional(c.default || c))
export const ExpedienteConfirmLevelUpDialog = () => import('../../components/expediente/ConfirmLevelUpDialog.vue' /* webpackChunkName: "components/expediente-confirm-level-up-dialog" */).then(c => wrapFunctional(c.default || c))
export const ExpedienteDesistDocuments = () => import('../../components/expediente/DesistDocuments.vue' /* webpackChunkName: "components/expediente-desist-documents" */).then(c => wrapFunctional(c.default || c))
export const ExpedienteDocuments = () => import('../../components/expediente/Documents.vue' /* webpackChunkName: "components/expediente-documents" */).then(c => wrapFunctional(c.default || c))
export const ExpedienteDocumentsFinals = () => import('../../components/expediente/DocumentsFinals.vue' /* webpackChunkName: "components/expediente-documents-finals" */).then(c => wrapFunctional(c.default || c))
export const ExpedienteInspections = () => import('../../components/expediente/Inspections.vue' /* webpackChunkName: "components/expediente-inspections" */).then(c => wrapFunctional(c.default || c))
export const ExpedienteLevelUpDialog = () => import('../../components/expediente/LevelUpDialog.vue' /* webpackChunkName: "components/expediente-level-up-dialog" */).then(c => wrapFunctional(c.default || c))
export const ExpedienteQuestionUnlinkWallet = () => import('../../components/expediente/QuestionUnlinkWallet.vue' /* webpackChunkName: "components/expediente-question-unlink-wallet" */).then(c => wrapFunctional(c.default || c))
export const ExpedienteSubmoduleContent = () => import('../../components/expediente/SubmoduleContent.vue' /* webpackChunkName: "components/expediente-submodule-content" */).then(c => wrapFunctional(c.default || c))
export const ExpedienteUnlinkWallet = () => import('../../components/expediente/UnlinkWallet.vue' /* webpackChunkName: "components/expediente-unlink-wallet" */).then(c => wrapFunctional(c.default || c))
export const FormsFormBuilderMin = () => import('../../components/forms/FormBuilderMin.vue' /* webpackChunkName: "components/forms-form-builder-min" */).then(c => wrapFunctional(c.default || c))
export const FormsFormFill = () => import('../../components/forms/FormFill.vue' /* webpackChunkName: "components/forms-form-fill" */).then(c => wrapFunctional(c.default || c))
export const FormsFormFillOperator = () => import('../../components/forms/FormFillOperator.vue' /* webpackChunkName: "components/forms-form-fill-operator" */).then(c => wrapFunctional(c.default || c))
export const FormsInvalidAnswersDialog = () => import('../../components/forms/InvalidAnswersDialog.vue' /* webpackChunkName: "components/forms-invalid-answers-dialog" */).then(c => wrapFunctional(c.default || c))
export const FormsInvalidApiRequestDialog = () => import('../../components/forms/InvalidApiRequestDialog.vue' /* webpackChunkName: "components/forms-invalid-api-request-dialog" */).then(c => wrapFunctional(c.default || c))
export const FormsInvalidInformeDialog = () => import('../../components/forms/InvalidInformeDialog.vue' /* webpackChunkName: "components/forms-invalid-informe-dialog" */).then(c => wrapFunctional(c.default || c))
export const FormsStepper = () => import('../../components/forms/Stepper.vue' /* webpackChunkName: "components/forms-stepper" */).then(c => wrapFunctional(c.default || c))
export const IconsAlertSoporteIcon = () => import('../../components/icons/AlertSoporteIcon.vue' /* webpackChunkName: "components/icons-alert-soporte-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsAlertWarningIcon = () => import('../../components/icons/AlertWarningIcon.vue' /* webpackChunkName: "components/icons-alert-warning-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsAllSelect = () => import('../../components/icons/AllSelect.vue' /* webpackChunkName: "components/icons-all-select" */).then(c => wrapFunctional(c.default || c))
export const IconsApoderadosIcon = () => import('../../components/icons/ApoderadosIcon.vue' /* webpackChunkName: "components/icons-apoderados-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsApoderadosPorMiIcon = () => import('../../components/icons/ApoderadosPorMiIcon.vue' /* webpackChunkName: "components/icons-apoderados-por-mi-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsAppleIcon = () => import('../../components/icons/AppleIcon.vue' /* webpackChunkName: "components/icons-apple-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsCalendarIcon = () => import('../../components/icons/CalendarIcon.vue' /* webpackChunkName: "components/icons-calendar-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsCancelIcon = () => import('../../components/icons/CancelIcon.vue' /* webpackChunkName: "components/icons-cancel-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsChatTabIcon = () => import('../../components/icons/ChatTabIcon.vue' /* webpackChunkName: "components/icons-chat-tab-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsCheckIcon = () => import('../../components/icons/CheckIcon.vue' /* webpackChunkName: "components/icons-check-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsCircleAnimationIcon = () => import('../../components/icons/CircleAnimationIcon.vue' /* webpackChunkName: "components/icons-circle-animation-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsCitizenIcon = () => import('../../components/icons/CitizenIcon.vue' /* webpackChunkName: "components/icons-citizen-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsCloudUpload = () => import('../../components/icons/CloudUpload.vue' /* webpackChunkName: "components/icons-cloud-upload" */).then(c => wrapFunctional(c.default || c))
export const IconsConfigIcon = () => import('../../components/icons/ConfigIcon.vue' /* webpackChunkName: "components/icons-config-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsCubeAnimation = () => import('../../components/icons/CubeAnimation.vue' /* webpackChunkName: "components/icons-cube-animation" */).then(c => wrapFunctional(c.default || c))
export const IconsDeleteIcon = () => import('../../components/icons/DeleteIcon.vue' /* webpackChunkName: "components/icons-delete-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsDocIcon = () => import('../../components/icons/DocIcon.vue' /* webpackChunkName: "components/icons-doc-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsDocumentosTabIcon = () => import('../../components/icons/DocumentosTabIcon.vue' /* webpackChunkName: "components/icons-documentos-tab-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsEditIcon = () => import('../../components/icons/EditIcon.vue' /* webpackChunkName: "components/icons-edit-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsEmpresasIcon = () => import('../../components/icons/EmpresasIcon.vue' /* webpackChunkName: "components/icons-empresas-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsEraserIcon = () => import('../../components/icons/EraserIcon.vue' /* webpackChunkName: "components/icons-eraser-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsErrorAnimationIcon = () => import('../../components/icons/ErrorAnimationIcon.vue' /* webpackChunkName: "components/icons-error-animation-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsExclamationIcon = () => import('../../components/icons/ExclamationIcon.vue' /* webpackChunkName: "components/icons-exclamation-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsExpedienteIcon = () => import('../../components/icons/ExpedienteIcon.vue' /* webpackChunkName: "components/icons-expediente-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsFichaPublicaIcon = () => import('../../components/icons/FichaPublicaIcon.vue' /* webpackChunkName: "components/icons-ficha-publica-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsFileHomeIcon = () => import('../../components/icons/FileHomeIcon.vue' /* webpackChunkName: "components/icons-file-home-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsFileIcon = () => import('../../components/icons/FileIcon.vue' /* webpackChunkName: "components/icons-file-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsFilterIcon = () => import('../../components/icons/FilterIcon.vue' /* webpackChunkName: "components/icons-filter-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsFolderIcon = () => import('../../components/icons/FolderIcon.vue' /* webpackChunkName: "components/icons-folder-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsFolioIcon = () => import('../../components/icons/FolioIcon.vue' /* webpackChunkName: "components/icons-folio-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsFormIcon = () => import('../../components/icons/FormIcon.vue' /* webpackChunkName: "components/icons-form-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsFormularioTabIcon = () => import('../../components/icons/FormularioTabIcon.vue' /* webpackChunkName: "components/icons-formulario-tab-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsIdentidadIcon = () => import('../../components/icons/IdentidadIcon.vue' /* webpackChunkName: "components/icons-identidad-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsIdentityIcon = () => import('../../components/icons/IdentityIcon.vue' /* webpackChunkName: "components/icons-identity-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsLicenseIcon = () => import('../../components/icons/LicenseIcon.vue' /* webpackChunkName: "components/icons-license-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsListPostSidebarIcon = () => import('../../components/icons/ListPostSidebarIcon.vue' /* webpackChunkName: "components/icons-list-post-sidebar-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsListPostSidebarIconClose = () => import('../../components/icons/ListPostSidebarIconClose.vue' /* webpackChunkName: "components/icons-list-post-sidebar-icon-close" */).then(c => wrapFunctional(c.default || c))
export const IconsNlWalletIcon = () => import('../../components/icons/NlWalletIcon.vue' /* webpackChunkName: "components/icons-nl-wallet-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsNotasTabIcon = () => import('../../components/icons/NotasTabIcon.vue' /* webpackChunkName: "components/icons-notas-tab-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsPersonaJuridica = () => import('../../components/icons/PersonaJuridica.vue' /* webpackChunkName: "components/icons-persona-juridica" */).then(c => wrapFunctional(c.default || c))
export const IconsPersonasQueApoderaron = () => import('../../components/icons/PersonasQueApoderaron.vue' /* webpackChunkName: "components/icons-personas-que-apoderaron" */).then(c => wrapFunctional(c.default || c))
export const IconsPortadocumentosIcon = () => import('../../components/icons/PortadocumentosIcon.vue' /* webpackChunkName: "components/icons-portadocumentos-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSearchIcon = () => import('../../components/icons/SearchIcon.vue' /* webpackChunkName: "components/icons-search-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsServDigitalIcon = () => import('../../components/icons/ServDigitalIcon.vue' /* webpackChunkName: "components/icons-serv-digital-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSubirNovelIcon = () => import('../../components/icons/SubirNovelIcon.vue' /* webpackChunkName: "components/icons-subir-novel-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSubmoduleIcon = () => import('../../components/icons/SubmoduleIcon.vue' /* webpackChunkName: "components/icons-submodule-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSuccessAnimationIcon = () => import('../../components/icons/SuccessAnimationIcon.vue' /* webpackChunkName: "components/icons-success-animation-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsTelephoneIcon = () => import('../../components/icons/TelephoneIcon.vue' /* webpackChunkName: "components/icons-telephone-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsTimeIcon = () => import('../../components/icons/TimeIcon.vue' /* webpackChunkName: "components/icons-time-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsToggleIcon = () => import('../../components/icons/ToggleIcon.vue' /* webpackChunkName: "components/icons-toggle-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsUserIcon = () => import('../../components/icons/UserIcon.vue' /* webpackChunkName: "components/icons-user-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsUserIconHeader = () => import('../../components/icons/UserIconHeader.vue' /* webpackChunkName: "components/icons-user-icon-header" */).then(c => wrapFunctional(c.default || c))
export const IconsViewIcon = () => import('../../components/icons/ViewIcon.vue' /* webpackChunkName: "components/icons-view-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsWalletIcon = () => import('../../components/icons/WalletIcon.vue' /* webpackChunkName: "components/icons-wallet-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsWarningAnimationIcon = () => import('../../components/icons/WarningAnimationIcon.vue' /* webpackChunkName: "components/icons-warning-animation-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsWhatIsIcon = () => import('../../components/icons/WhatIsIcon.vue' /* webpackChunkName: "components/icons-what-is-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsAutenticarAfip = () => import('../../components/icons/autenticar-afip.vue' /* webpackChunkName: "components/icons-autenticar-afip" */).then(c => wrapFunctional(c.default || c))
export const IconsAutenticarAnses = () => import('../../components/icons/autenticar-anses.vue' /* webpackChunkName: "components/icons-autenticar-anses" */).then(c => wrapFunctional(c.default || c))
export const IconsAutenticarDni = () => import('../../components/icons/autenticar-dni.vue' /* webpackChunkName: "components/icons-autenticar-dni" */).then(c => wrapFunctional(c.default || c))
export const IconsAutenticarMa = () => import('../../components/icons/autenticar-ma.vue' /* webpackChunkName: "components/icons-autenticar-ma" */).then(c => wrapFunctional(c.default || c))
export const IconsFolio = () => import('../../components/icons/folio.vue' /* webpackChunkName: "components/icons-folio" */).then(c => wrapFunctional(c.default || c))
export const IconsOscityDocumentos = () => import('../../components/icons/oscity-documentos.vue' /* webpackChunkName: "components/icons-oscity-documentos" */).then(c => wrapFunctional(c.default || c))
export const IconsOscityGobierno = () => import('../../components/icons/oscity-gobierno.vue' /* webpackChunkName: "components/icons-oscity-gobierno" */).then(c => wrapFunctional(c.default || c))
export const IconsOscityIdentidad = () => import('../../components/icons/oscity-identidad.vue' /* webpackChunkName: "components/icons-oscity-identidad" */).then(c => wrapFunctional(c.default || c))
export const IconsWorkTable = () => import('../../components/icons/work-table.vue' /* webpackChunkName: "components/icons-work-table" */).then(c => wrapFunctional(c.default || c))
export const InspectorsInspectionDetails = () => import('../../components/inspectors/InspectionDetails.vue' /* webpackChunkName: "components/inspectors-inspection-details" */).then(c => wrapFunctional(c.default || c))
export const InspectorsInspectionQuickView = () => import('../../components/inspectors/InspectionQuickView.vue' /* webpackChunkName: "components/inspectors-inspection-quick-view" */).then(c => wrapFunctional(c.default || c))
export const InspectorsInspectionsAssignCard = () => import('../../components/inspectors/InspectionsAssignCard.vue' /* webpackChunkName: "components/inspectors-inspections-assign-card" */).then(c => wrapFunctional(c.default || c))
export const InspectorsInspectionsAssignInbox = () => import('../../components/inspectors/InspectionsAssignInbox.vue' /* webpackChunkName: "components/inspectors-inspections-assign-inbox" */).then(c => wrapFunctional(c.default || c))
export const InspectorsInspectionsInbox = () => import('../../components/inspectors/InspectionsInbox.vue' /* webpackChunkName: "components/inspectors-inspections-inbox" */).then(c => wrapFunctional(c.default || c))
export const InspectorsInspectionsList = () => import('../../components/inspectors/InspectionsList.vue' /* webpackChunkName: "components/inspectors-inspections-list" */).then(c => wrapFunctional(c.default || c))
export const InspectorsInspectorInfo = () => import('../../components/inspectors/InspectorInfo.vue' /* webpackChunkName: "components/inspectors-inspector-info" */).then(c => wrapFunctional(c.default || c))
export const InspectorsReasignInspection = () => import('../../components/inspectors/ReasignInspection.vue' /* webpackChunkName: "components/inspectors-reasign-inspection" */).then(c => wrapFunctional(c.default || c))
export const InspectorsUploadDocsInspection = () => import('../../components/inspectors/UploadDocsInspection.vue' /* webpackChunkName: "components/inspectors-upload-docs-inspection" */).then(c => wrapFunctional(c.default || c))
export const InspectorsUploadFilesInspectors = () => import('../../components/inspectors/UploadFilesInspectors.vue' /* webpackChunkName: "components/inspectors-upload-files-inspectors" */).then(c => wrapFunctional(c.default || c))
export const OperatorsAlertCard = () => import('../../components/operators/AlertCard.vue' /* webpackChunkName: "components/operators-alert-card" */).then(c => wrapFunctional(c.default || c))
export const OperatorsDetails = () => import('../../components/operators/Details.vue' /* webpackChunkName: "components/operators-details" */).then(c => wrapFunctional(c.default || c))
export const OperatorsDownloadFiles = () => import('../../components/operators/DownloadFiles.vue' /* webpackChunkName: "components/operators-download-files" */).then(c => wrapFunctional(c.default || c))
export const OperatorsExportButton = () => import('../../components/operators/ExportButton.vue' /* webpackChunkName: "components/operators-export-button" */).then(c => wrapFunctional(c.default || c))
export const OperatorsInbox = () => import('../../components/operators/Inbox.vue' /* webpackChunkName: "components/operators-inbox" */).then(c => wrapFunctional(c.default || c))
export const OperatorsListUser = () => import('../../components/operators/ListUser.vue' /* webpackChunkName: "components/operators-list-user" */).then(c => wrapFunctional(c.default || c))
export const PaymentsDataDocument = () => import('../../components/payments/DataDocument.vue' /* webpackChunkName: "components/payments-data-document" */).then(c => wrapFunctional(c.default || c))
export const PaymentsDocPayment = () => import('../../components/payments/DocPayment.vue' /* webpackChunkName: "components/payments-doc-payment" */).then(c => wrapFunctional(c.default || c))
export const PaymentsMotorDePagos = () => import('../../components/payments/MotorDePagos.vue' /* webpackChunkName: "components/payments-motor-de-pagos" */).then(c => wrapFunctional(c.default || c))
export const PaymentsOrdenPayment = () => import('../../components/payments/OrdenPayment.vue' /* webpackChunkName: "components/payments-orden-payment" */).then(c => wrapFunctional(c.default || c))
export const PaymentsPaymentDataCard = () => import('../../components/payments/PaymentDataCard.vue' /* webpackChunkName: "components/payments-payment-data-card" */).then(c => wrapFunctional(c.default || c))
export const PaymentsPaymentPrintVersion = () => import('../../components/payments/PaymentPrintVersion.vue' /* webpackChunkName: "components/payments-payment-print-version" */).then(c => wrapFunctional(c.default || c))
export const PaymentsDashboard = () => import('../../components/payments/PaymentsDashboard.vue' /* webpackChunkName: "components/payments-dashboard" */).then(c => wrapFunctional(c.default || c))
export const PaymentsImg = () => import('../../components/payments/PaymentsImg.vue' /* webpackChunkName: "components/payments-img" */).then(c => wrapFunctional(c.default || c))
export const PaymentsSidebarInbox = () => import('../../components/payments/SidebarInbox.vue' /* webpackChunkName: "components/payments-sidebar-inbox" */).then(c => wrapFunctional(c.default || c))
export const PlaylistAddContent = () => import('../../components/playlist/AddContent.vue' /* webpackChunkName: "components/playlist-add-content" */).then(c => wrapFunctional(c.default || c))
export const PlaylistConstructorList = () => import('../../components/playlist/ConstructorList.vue' /* webpackChunkName: "components/playlist-constructor-list" */).then(c => wrapFunctional(c.default || c))
export const PlaylistListTramite = () => import('../../components/playlist/ListTramite.vue' /* webpackChunkName: "components/playlist-list-tramite" */).then(c => wrapFunctional(c.default || c))
export const PlaylistView = () => import('../../components/playlist/PlaylistView.vue' /* webpackChunkName: "components/playlist-view" */).then(c => wrapFunctional(c.default || c))
export const PlaylistProcedureInfo = () => import('../../components/playlist/ProcedureInfo.vue' /* webpackChunkName: "components/playlist-procedure-info" */).then(c => wrapFunctional(c.default || c))
export const PlaylistProceduresDialog = () => import('../../components/playlist/ProceduresDialog.vue' /* webpackChunkName: "components/playlist-procedures-dialog" */).then(c => wrapFunctional(c.default || c))
export const PlaylistSidebarPlaylist = () => import('../../components/playlist/SidebarPlaylist.vue' /* webpackChunkName: "components/playlist-sidebar-playlist" */).then(c => wrapFunctional(c.default || c))
export const PlaylistSlideGroup = () => import('../../components/playlist/SlideGroup.vue' /* webpackChunkName: "components/playlist-slide-group" */).then(c => wrapFunctional(c.default || c))
export const PublicAvatarMenu = () => import('../../components/public/AvatarMenu.vue' /* webpackChunkName: "components/public-avatar-menu" */).then(c => wrapFunctional(c.default || c))
export const PublicContact = () => import('../../components/public/Contact.vue' /* webpackChunkName: "components/public-contact" */).then(c => wrapFunctional(c.default || c))
export const PublicDialogIdle = () => import('../../components/public/DialogIdle.vue' /* webpackChunkName: "components/public-dialog-idle" */).then(c => wrapFunctional(c.default || c))
export const PublicFooter = () => import('../../components/public/Footer.vue' /* webpackChunkName: "components/public-footer" */).then(c => wrapFunctional(c.default || c))
export const PublicLoaderFull = () => import('../../components/public/LoaderFull.vue' /* webpackChunkName: "components/public-loader-full" */).then(c => wrapFunctional(c.default || c))
export const PublicLoaderNotify = () => import('../../components/public/LoaderNotify.vue' /* webpackChunkName: "components/public-loader-notify" */).then(c => wrapFunctional(c.default || c))
export const PublicLogo = () => import('../../components/public/Logo.vue' /* webpackChunkName: "components/public-logo" */).then(c => wrapFunctional(c.default || c))
export const PublicNotification = () => import('../../components/public/Notification.vue' /* webpackChunkName: "components/public-notification" */).then(c => wrapFunctional(c.default || c))
export const PublicSearchAlgolia = () => import('../../components/public/SearchAlgolia.vue' /* webpackChunkName: "components/public-search-algolia" */).then(c => wrapFunctional(c.default || c))
export const PublicSidebar = () => import('../../components/public/Sidebar.vue' /* webpackChunkName: "components/public-sidebar" */).then(c => wrapFunctional(c.default || c))
export const SettingsAppearance = () => import('../../components/settings/Appearance.vue' /* webpackChunkName: "components/settings-appearance" */).then(c => wrapFunctional(c.default || c))
export const SettingsGeneral = () => import('../../components/settings/General.vue' /* webpackChunkName: "components/settings-general" */).then(c => wrapFunctional(c.default || c))
export const SettingsMap = () => import('../../components/settings/Map.vue' /* webpackChunkName: "components/settings-map" */).then(c => wrapFunctional(c.default || c))
export const SettingsSeo = () => import('../../components/settings/Seo.vue' /* webpackChunkName: "components/settings-seo" */).then(c => wrapFunctional(c.default || c))
export const SidebarCiudadano = () => import('../../components/sidebar/SidebarCiudadano.vue' /* webpackChunkName: "components/sidebar-ciudadano" */).then(c => wrapFunctional(c.default || c))
export const SidebarFuncionario = () => import('../../components/sidebar/SidebarFuncionario.vue' /* webpackChunkName: "components/sidebar-funcionario" */).then(c => wrapFunctional(c.default || c))
export const TiptapMenuBar = () => import('../../components/tiptap/MenuBar.vue' /* webpackChunkName: "components/tiptap-menu-bar" */).then(c => wrapFunctional(c.default || c))
export const TiptapMenuItem = () => import('../../components/tiptap/MenuItem.vue' /* webpackChunkName: "components/tiptap-menu-item" */).then(c => wrapFunctional(c.default || c))
export const AuthBackgroundImagesLinkWalletImage = () => import('../../components/Auth/BackgroundImages/LinkWalletImage.vue' /* webpackChunkName: "components/auth-background-images-link-wallet-image" */).then(c => wrapFunctional(c.default || c))
export const AuthBackgroundImagesLoginImage = () => import('../../components/Auth/BackgroundImages/LoginImage.vue' /* webpackChunkName: "components/auth-background-images-login-image" */).then(c => wrapFunctional(c.default || c))
export const AuthBackgroundImagesResetPasswordImage = () => import('../../components/Auth/BackgroundImages/ResetPasswordImage.vue' /* webpackChunkName: "components/auth-background-images-reset-password-image" */).then(c => wrapFunctional(c.default || c))
export const AuthBackgroundImagesResetPasswordImage2 = () => import('../../components/Auth/BackgroundImages/ResetPasswordImage2.vue' /* webpackChunkName: "components/auth-background-images-reset-password-image2" */).then(c => wrapFunctional(c.default || c))
export const AuthBackgroundImagesTermsImageNl = () => import('../../components/Auth/BackgroundImages/TermsImageNl.vue' /* webpackChunkName: "components/auth-background-images-terms-image-nl" */).then(c => wrapFunctional(c.default || c))
export const CertificatesEditorMenuBarCerts = () => import('../../components/Certificates/Editor/MenuBarCerts.vue' /* webpackChunkName: "components/certificates-editor-menu-bar-certs" */).then(c => wrapFunctional(c.default || c))
export const CertificatesEditorTextEditorCerts = () => import('../../components/Certificates/Editor/TextEditorCerts.vue' /* webpackChunkName: "components/certificates-editor-text-editor-certs" */).then(c => wrapFunctional(c.default || c))
export const CredentialsActionsCreate = () => import('../../components/Credentials/Actions/Create.vue' /* webpackChunkName: "components/credentials-actions-create" */).then(c => wrapFunctional(c.default || c))
export const CredentialsActionsRevoke = () => import('../../components/Credentials/Actions/Revoke.vue' /* webpackChunkName: "components/credentials-actions-revoke" */).then(c => wrapFunctional(c.default || c))
export const CredentialsTabsConnections = () => import('../../components/Credentials/Tabs/Connections.vue' /* webpackChunkName: "components/credentials-tabs-connections" */).then(c => wrapFunctional(c.default || c))
export const CredentialsTabsDashboard = () => import('../../components/Credentials/Tabs/Dashboard.vue' /* webpackChunkName: "components/credentials-tabs-dashboard" */).then(c => wrapFunctional(c.default || c))
export const CredentialsTabsIssued = () => import('../../components/Credentials/Tabs/Issued.vue' /* webpackChunkName: "components/credentials-tabs-issued" */).then(c => wrapFunctional(c.default || c))
export const CredentialsTabsVerifications = () => import('../../components/Credentials/Tabs/Verifications.vue' /* webpackChunkName: "components/credentials-tabs-verifications" */).then(c => wrapFunctional(c.default || c))
export const FinalDocumentAcknowledgmentTypesNoticesRecReception = () => import('../../components/FinalDocument/AcknowledgmentTypes/NoticesRecReception.vue' /* webpackChunkName: "components/final-document-acknowledgment-types-notices-rec-reception" */).then(c => wrapFunctional(c.default || c))
export const FinalDocumentAcknowledgmentTypesNoticesRecResponse = () => import('../../components/FinalDocument/AcknowledgmentTypes/NoticesRecResponse.vue' /* webpackChunkName: "components/final-document-acknowledgment-types-notices-rec-response" */).then(c => wrapFunctional(c.default || c))
export const FinalDocumentAcknowledgmentTypesRecReception = () => import('../../components/FinalDocument/AcknowledgmentTypes/RecReception.vue' /* webpackChunkName: "components/final-document-acknowledgment-types-rec-reception" */).then(c => wrapFunctional(c.default || c))
export const FinalDocumentAcknowledgmentTypesRecResponse = () => import('../../components/FinalDocument/AcknowledgmentTypes/RecResponse.vue' /* webpackChunkName: "components/final-document-acknowledgment-types-rec-response" */).then(c => wrapFunctional(c.default || c))
export const FinalDocumentAcknowledgmentTypesReception = () => import('../../components/FinalDocument/AcknowledgmentTypes/Reception.vue' /* webpackChunkName: "components/final-document-acknowledgment-types-reception" */).then(c => wrapFunctional(c.default || c))
export const FinalDocumentAcknowledgmentTypesResponse = () => import('../../components/FinalDocument/AcknowledgmentTypes/Response.vue' /* webpackChunkName: "components/final-document-acknowledgment-types-response" */).then(c => wrapFunctional(c.default || c))
export const MapIdeFilters = () => import('../../components/Map/ide/Filters.vue' /* webpackChunkName: "components/map-ide-filters" */).then(c => wrapFunctional(c.default || c))
export const PostsInformationSheetAllContentAccordion = () => import('../../components/Posts/InformationSheet/AllContentAccordion.vue' /* webpackChunkName: "components/posts-information-sheet-all-content-accordion" */).then(c => wrapFunctional(c.default || c))
export const PostsInformationSheetContent = () => import('../../components/Posts/InformationSheet/Content.vue' /* webpackChunkName: "components/posts-information-sheet-content" */).then(c => wrapFunctional(c.default || c))
export const PostsInformationSheetContentPrincipal = () => import('../../components/Posts/InformationSheet/ContentPrincipal.vue' /* webpackChunkName: "components/posts-information-sheet-content-principal" */).then(c => wrapFunctional(c.default || c))
export const PostsInformationSheetContentRight = () => import('../../components/Posts/InformationSheet/ContentRight.vue' /* webpackChunkName: "components/posts-information-sheet-content-right" */).then(c => wrapFunctional(c.default || c))
export const PostsInformationSheetDownloadFileCard = () => import('../../components/Posts/InformationSheet/DownloadFileCard.vue' /* webpackChunkName: "components/posts-information-sheet-download-file-card" */).then(c => wrapFunctional(c.default || c))
export const PostsInformationSheetHeaderCard = () => import('../../components/Posts/InformationSheet/HeaderCard.vue' /* webpackChunkName: "components/posts-information-sheet-header-card" */).then(c => wrapFunctional(c.default || c))
export const PostsInformationSheetHeaderCardContent = () => import('../../components/Posts/InformationSheet/HeaderCardContent.vue' /* webpackChunkName: "components/posts-information-sheet-header-card-content" */).then(c => wrapFunctional(c.default || c))
export const PostsInformationSheetPdfContent = () => import('../../components/Posts/InformationSheet/PdfContent.vue' /* webpackChunkName: "components/posts-information-sheet-pdf-content" */).then(c => wrapFunctional(c.default || c))
export const PostsInformationSheetProceduresBtn = () => import('../../components/Posts/InformationSheet/ProceduresBtn.vue' /* webpackChunkName: "components/posts-information-sheet-procedures-btn" */).then(c => wrapFunctional(c.default || c))
export const PostsInformationSheetRatingCard = () => import('../../components/Posts/InformationSheet/RatingCard.vue' /* webpackChunkName: "components/posts-information-sheet-rating-card" */).then(c => wrapFunctional(c.default || c))
export const PostsInformationSheetSectionAccordion = () => import('../../components/Posts/InformationSheet/SectionAccordion.vue' /* webpackChunkName: "components/posts-information-sheet-section-accordion" */).then(c => wrapFunctional(c.default || c))
export const PostsInformationSheetSubHeaderCardContent = () => import('../../components/Posts/InformationSheet/SubHeaderCardContent.vue' /* webpackChunkName: "components/posts-information-sheet-sub-header-card-content" */).then(c => wrapFunctional(c.default || c))
export const PostsInformationSheetSubheaderCard = () => import('../../components/Posts/InformationSheet/SubheaderCard.vue' /* webpackChunkName: "components/posts-information-sheet-subheader-card" */).then(c => wrapFunctional(c.default || c))
export const PostsInformationSheetTitle = () => import('../../components/Posts/InformationSheet/Title.vue' /* webpackChunkName: "components/posts-information-sheet-title" */).then(c => wrapFunctional(c.default || c))
export const PostsDigitalServicesCitizen = () => import('../../components/Posts/digitalServices/citizen.vue' /* webpackChunkName: "components/posts-digital-services-citizen" */).then(c => wrapFunctional(c.default || c))
export const PostsDigitalServicesFuncionario = () => import('../../components/Posts/digitalServices/funcionario.vue' /* webpackChunkName: "components/posts-digital-services-funcionario" */).then(c => wrapFunctional(c.default || c))
export const TemplateBuilderV1CertPrintElement = () => import('../../components/TemplateBuilder/v1/CertPrintElement.vue' /* webpackChunkName: "components/template-builder-v1-cert-print-element" */).then(c => wrapFunctional(c.default || c))
export const TemplateBuilderV1CertificateOutputFormat = () => import('../../components/TemplateBuilder/v1/CertificateOutputFormat.vue' /* webpackChunkName: "components/template-builder-v1-certificate-output-format" */).then(c => wrapFunctional(c.default || c))
export const TemplateBuilderV1CertificateTemplateOld = () => import('../../components/TemplateBuilder/v1/CertificateTemplateOld.vue' /* webpackChunkName: "components/template-builder-v1-certificate-template-old" */).then(c => wrapFunctional(c.default || c))
export const TemplateBuilderV2CatalogsSidebar = () => import('../../components/TemplateBuilder/v2/CatalogsSidebar.vue' /* webpackChunkName: "components/template-builder-v2-catalogs-sidebar" */).then(c => wrapFunctional(c.default || c))
export const TemplateBuilderV2CertPrintField = () => import('../../components/TemplateBuilder/v2/CertPrintField.vue' /* webpackChunkName: "components/template-builder-v2-cert-print-field" */).then(c => wrapFunctional(c.default || c))
export const TemplateBuilderV2CertificateTemplate = () => import('../../components/TemplateBuilder/v2/CertificateTemplate.vue' /* webpackChunkName: "components/template-builder-v2-certificate-template" */).then(c => wrapFunctional(c.default || c))
export const TemplateBuilderV2FinalDocumentOutput = () => import('../../components/TemplateBuilder/v2/FinalDocumentOutput.vue' /* webpackChunkName: "components/template-builder-v2-final-document-output" */).then(c => wrapFunctional(c.default || c))
export const TemplateBuilderV2QrField = () => import('../../components/TemplateBuilder/v2/QrField.vue' /* webpackChunkName: "components/template-builder-v2-qr-field" */).then(c => wrapFunctional(c.default || c))
export const TemplateBuilderV2StructureSidebar = () => import('../../components/TemplateBuilder/v2/StructureSidebar.vue' /* webpackChunkName: "components/template-builder-v2-structure-sidebar" */).then(c => wrapFunctional(c.default || c))
export const TemplateBuilderSidebar = () => import('../../components/TemplateBuilder/v2/TemplateBuilderSidebar.vue' /* webpackChunkName: "components/template-builder-sidebar" */).then(c => wrapFunctional(c.default || c))
export const TemplateBuilderV = () => import('../../components/TemplateBuilder/v2/TemplateBuilderV.vue' /* webpackChunkName: "components/template-builder-v" */).then(c => wrapFunctional(c.default || c))
export const TemplateOutput = () => import('../../components/TemplateBuilder/v2/TemplateOutput.vue' /* webpackChunkName: "components/template-output" */).then(c => wrapFunctional(c.default || c))
export const TemplateBuilderV2UploadImgAndPreview = () => import('../../components/TemplateBuilder/v2/UploadImgAndPreview.vue' /* webpackChunkName: "components/template-builder-v2-upload-img-and-preview" */).then(c => wrapFunctional(c.default || c))
export const UIEditorMenuBar = () => import('../../components/UI/Editor/MenuBar.vue' /* webpackChunkName: "components/u-i-editor-menu-bar" */).then(c => wrapFunctional(c.default || c))
export const UIEditorTextAreaEditor = () => import('../../components/UI/Editor/TextAreaEditor.vue' /* webpackChunkName: "components/u-i-editor-text-area-editor" */).then(c => wrapFunctional(c.default || c))
export const UIEditorTextEditor = () => import('../../components/UI/Editor/TextEditor.vue' /* webpackChunkName: "components/u-i-editor-text-editor" */).then(c => wrapFunctional(c.default || c))
export const UsersConfigurationAddUser = () => import('../../components/Users/configuration/AddUser.vue' /* webpackChunkName: "components/users-configuration-add-user" */).then(c => wrapFunctional(c.default || c))
export const UsersConfigurationCitizen = () => import('../../components/Users/configuration/Citizen.vue' /* webpackChunkName: "components/users-configuration-citizen" */).then(c => wrapFunctional(c.default || c))
export const UsersConfigurationCitizenDetail = () => import('../../components/Users/configuration/CitizenDetail.vue' /* webpackChunkName: "components/users-configuration-citizen-detail" */).then(c => wrapFunctional(c.default || c))
export const UsersConfigurationCompanies = () => import('../../components/Users/configuration/Companies.vue' /* webpackChunkName: "components/users-configuration-companies" */).then(c => wrapFunctional(c.default || c))
export const UsersConfigurationCompanyDetail = () => import('../../components/Users/configuration/CompanyDetail.vue' /* webpackChunkName: "components/users-configuration-company-detail" */).then(c => wrapFunctional(c.default || c))
export const UsersConfiguration = () => import('../../components/Users/configuration/Configuration.vue' /* webpackChunkName: "components/users-configuration" */).then(c => wrapFunctional(c.default || c))
export const UsersConfigurationFunctionary = () => import('../../components/Users/configuration/Functionary.vue' /* webpackChunkName: "components/users-configuration-functionary" */).then(c => wrapFunctional(c.default || c))
export const UsersConfigurationFunctionaryDetail = () => import('../../components/Users/configuration/FunctionaryDetail.vue' /* webpackChunkName: "components/users-configuration-functionary-detail" */).then(c => wrapFunctional(c.default || c))
export const UsersConfigurationInstitutionalCitDetail = () => import('../../components/Users/configuration/InstitutionalCitDetail.vue' /* webpackChunkName: "components/users-configuration-institutional-cit-detail" */).then(c => wrapFunctional(c.default || c))
export const UsersConfigurationInstitutionalCitizen = () => import('../../components/Users/configuration/InstitutionalCitizen.vue' /* webpackChunkName: "components/users-configuration-institutional-citizen" */).then(c => wrapFunctional(c.default || c))
export const UsersConfigurationRoles = () => import('../../components/Users/configuration/Roles.vue' /* webpackChunkName: "components/users-configuration-roles" */).then(c => wrapFunctional(c.default || c))
export const UsersConfigurationVisualizerDetail = () => import('../../components/Users/configuration/VisualizerDetail.vue' /* webpackChunkName: "components/users-configuration-visualizer-detail" */).then(c => wrapFunctional(c.default || c))
export const UsersConfigurationVisualizers = () => import('../../components/Users/configuration/Visualizers.vue' /* webpackChunkName: "components/users-configuration-visualizers" */).then(c => wrapFunctional(c.default || c))
export const ExpedienteDetailsAppointmentsList = () => import('../../components/expediente/details/AppointmentsList.vue' /* webpackChunkName: "components/expediente-details-appointments-list" */).then(c => wrapFunctional(c.default || c))
export const ExpedienteDetailsDesistBtn = () => import('../../components/expediente/details/DesistBtn.vue' /* webpackChunkName: "components/expediente-details-desist-btn" */).then(c => wrapFunctional(c.default || c))
export const ExpedienteDetailsDocuments = () => import('../../components/expediente/details/Documents.vue' /* webpackChunkName: "components/expediente-details-documents" */).then(c => wrapFunctional(c.default || c))
export const ExpedienteDetailsFileDialog = () => import('../../components/expediente/details/FileDialog.vue' /* webpackChunkName: "components/expediente-details-file-dialog" */).then(c => wrapFunctional(c.default || c))
export const ExpedienteDetailsGroupQuestionsFileDialog = () => import('../../components/expediente/details/GroupQuestionsFileDialog.vue' /* webpackChunkName: "components/expediente-details-group-questions-file-dialog" */).then(c => wrapFunctional(c.default || c))
export const ExpedienteDetailsIconsTabs = () => import('../../components/expediente/details/IconsTabs.vue' /* webpackChunkName: "components/expediente-details-icons-tabs" */).then(c => wrapFunctional(c.default || c))
export const ExpedienteDetailsNewRequests = () => import('../../components/expediente/details/NewRequests.vue' /* webpackChunkName: "components/expediente-details-new-requests" */).then(c => wrapFunctional(c.default || c))
export const ExpedienteDetailsPayments = () => import('../../components/expediente/details/Payments.vue' /* webpackChunkName: "components/expediente-details-payments" */).then(c => wrapFunctional(c.default || c))
export const ExpedienteDetailsTimeline = () => import('../../components/expediente/details/Timeline.vue' /* webpackChunkName: "components/expediente-details-timeline" */).then(c => wrapFunctional(c.default || c))
export const ExpedienteDetailsUploadFile = () => import('../../components/expediente/details/UploadFile.vue' /* webpackChunkName: "components/expediente-details-upload-file" */).then(c => wrapFunctional(c.default || c))
export const FormsCustomAddressf = () => import('../../components/forms/CustomComponents/CustomAddressf.vue' /* webpackChunkName: "components/forms-custom-addressf" */).then(c => wrapFunctional(c.default || c))
export const FormsCustomApi = () => import('../../components/forms/CustomComponents/CustomApi.vue' /* webpackChunkName: "components/forms-custom-api" */).then(c => wrapFunctional(c.default || c))
export const FormsCustomFileInput = () => import('../../components/forms/CustomComponents/CustomFileInput.vue' /* webpackChunkName: "components/forms-custom-file-input" */).then(c => wrapFunctional(c.default || c))
export const FormsCustomGroupQuestions = () => import('../../components/forms/CustomComponents/CustomGroupQuestions.vue' /* webpackChunkName: "components/forms-custom-group-questions" */).then(c => wrapFunctional(c.default || c))
export const FormsCustomIde = () => import('../../components/forms/CustomComponents/CustomIde.vue' /* webpackChunkName: "components/forms-custom-ide" */).then(c => wrapFunctional(c.default || c))
export const FormsCustomInformeUrbanistico = () => import('../../components/forms/CustomComponents/CustomInformeUrbanistico.vue' /* webpackChunkName: "components/forms-custom-informe-urbanistico" */).then(c => wrapFunctional(c.default || c))
export const FormsCustomInspectores = () => import('../../components/forms/CustomComponents/CustomInspectores.vue' /* webpackChunkName: "components/forms-custom-inspectores" */).then(c => wrapFunctional(c.default || c))
export const FormsCustomMask = () => import('../../components/forms/CustomComponents/CustomMask.vue' /* webpackChunkName: "components/forms-custom-mask" */).then(c => wrapFunctional(c.default || c))
export const FormsCustomPreventions = () => import('../../components/forms/CustomComponents/CustomPreventions.vue' /* webpackChunkName: "components/forms-custom-preventions" */).then(c => wrapFunctional(c.default || c))
export const FormsCustomSearch = () => import('../../components/forms/CustomComponents/CustomSearch.vue' /* webpackChunkName: "components/forms-custom-search" */).then(c => wrapFunctional(c.default || c))
export const FormsCustomComponentsTiptapInput = () => import('../../components/forms/CustomComponents/TiptapInput.vue' /* webpackChunkName: "components/forms-custom-components-tiptap-input" */).then(c => wrapFunctional(c.default || c))
export const FormsCustomComponentsTiptapOutput = () => import('../../components/forms/CustomComponents/TiptapOutput.vue' /* webpackChunkName: "components/forms-custom-components-tiptap-output" */).then(c => wrapFunctional(c.default || c))
export const FormsDataTable = () => import('../../components/forms/DataTables/DataTable.vue' /* webpackChunkName: "components/forms-data-table" */).then(c => wrapFunctional(c.default || c))
export const FormsDataTablesDownloadProceduresModule = () => import('../../components/forms/DataTables/DownloadProceduresModule.vue' /* webpackChunkName: "components/forms-data-tables-download-procedures-module" */).then(c => wrapFunctional(c.default || c))
export const FormsFormFillCompleteFormDialog = () => import('../../components/forms/FormFill/CompleteFormDialog.vue' /* webpackChunkName: "components/forms-form-fill-complete-form-dialog" */).then(c => wrapFunctional(c.default || c))
export const FormsFormFillCustomFormBase = () => import('../../components/forms/FormFill/CustomFormBase.vue' /* webpackChunkName: "components/forms-form-fill-custom-form-base" */).then(c => wrapFunctional(c.default || c))
export const FormsFormFillElectronicSignature = () => import('../../components/forms/FormFill/ElectronicSignature.vue' /* webpackChunkName: "components/forms-form-fill-electronic-signature" */).then(c => wrapFunctional(c.default || c))
export const FormsFormFillPrintVersionAnswer = () => import('../../components/forms/FormFill/PrintVersionAnswer.vue' /* webpackChunkName: "components/forms-form-fill-print-version-answer" */).then(c => wrapFunctional(c.default || c))
export const FormsFormFillPrivacyPolicyDialog = () => import('../../components/forms/FormFill/PrivacyPolicyDialog.vue' /* webpackChunkName: "components/forms-form-fill-privacy-policy-dialog" */).then(c => wrapFunctional(c.default || c))
export const FormsFormFillStatusDialog = () => import('../../components/forms/FormFill/StatusDialog.vue' /* webpackChunkName: "components/forms-form-fill-status-dialog" */).then(c => wrapFunctional(c.default || c))
export const FormsFormFillOperatorAppointmentsConfig = () => import('../../components/forms/FormFillOperator/AppointmentsConfig.vue' /* webpackChunkName: "components/forms-form-fill-operator-appointments-config" */).then(c => wrapFunctional(c.default || c))
export const FormsFormFillOperatorAssignmentPersons = () => import('../../components/forms/FormFillOperator/AssignmentPersons.vue' /* webpackChunkName: "components/forms-form-fill-operator-assignment-persons" */).then(c => wrapFunctional(c.default || c))
export const FormsFormFillOperatorChangesDialog = () => import('../../components/forms/FormFillOperator/ChangesDialog.vue' /* webpackChunkName: "components/forms-form-fill-operator-changes-dialog" */).then(c => wrapFunctional(c.default || c))
export const FormsFormFillOperatorConfirmationsDialog = () => import('../../components/forms/FormFillOperator/ConfirmationsDialog.vue' /* webpackChunkName: "components/forms-form-fill-operator-confirmations-dialog" */).then(c => wrapFunctional(c.default || c))
export const FormsFormFillOperatorDataDefault = () => import('../../components/forms/FormFillOperator/DataDefault.vue' /* webpackChunkName: "components/forms-form-fill-operator-data-default" */).then(c => wrapFunctional(c.default || c))
export const FormsFormFillOperatorDataPrincipal = () => import('../../components/forms/FormFillOperator/DataPrincipal.vue' /* webpackChunkName: "components/forms-form-fill-operator-data-principal" */).then(c => wrapFunctional(c.default || c))
export const FormsFormFillOperatorDraftsDialog = () => import('../../components/forms/FormFillOperator/DraftsDialog.vue' /* webpackChunkName: "components/forms-form-fill-operator-drafts-dialog" */).then(c => wrapFunctional(c.default || c))
export const FormsFormBuilder = () => import('../../components/forms/FormFillOperator/FormBuilder.vue' /* webpackChunkName: "components/forms-form-builder" */).then(c => wrapFunctional(c.default || c))
export const FormsFormFillOperatorGenerarFolio = () => import('../../components/forms/FormFillOperator/GenerarFolio.vue' /* webpackChunkName: "components/forms-form-fill-operator-generar-folio" */).then(c => wrapFunctional(c.default || c))
export const FormsFormFillOperatorNoSections = () => import('../../components/forms/FormFillOperator/NoSections.vue' /* webpackChunkName: "components/forms-form-fill-operator-no-sections" */).then(c => wrapFunctional(c.default || c))
export const FormsFormFillOperatorSettings = () => import('../../components/forms/FormFillOperator/Settings.vue' /* webpackChunkName: "components/forms-form-fill-operator-settings" */).then(c => wrapFunctional(c.default || c))
export const FormsFormFillOperatorSidebarForm = () => import('../../components/forms/FormFillOperator/SidebarForm.vue' /* webpackChunkName: "components/forms-form-fill-operator-sidebar-form" */).then(c => wrapFunctional(c.default || c))
export const FormsFormFillOperatorSidebarSettings = () => import('../../components/forms/FormFillOperator/SidebarSettings.vue' /* webpackChunkName: "components/forms-form-fill-operator-sidebar-settings" */).then(c => wrapFunctional(c.default || c))
export const FormsFormFillOperatorSidebarStages = () => import('../../components/forms/FormFillOperator/SidebarStages.vue' /* webpackChunkName: "components/forms-form-fill-operator-sidebar-stages" */).then(c => wrapFunctional(c.default || c))
export const FormsFormFillOperatorStages = () => import('../../components/forms/FormFillOperator/Stages.vue' /* webpackChunkName: "components/forms-form-fill-operator-stages" */).then(c => wrapFunctional(c.default || c))
export const FormsFormFillOperatorWhiteList = () => import('../../components/forms/FormFillOperator/WhiteList.vue' /* webpackChunkName: "components/forms-form-fill-operator-white-list" */).then(c => wrapFunctional(c.default || c))
export const FormsFormFillOperatorWithSections = () => import('../../components/forms/FormFillOperator/WithSections.vue' /* webpackChunkName: "components/forms-form-fill-operator-with-sections" */).then(c => wrapFunctional(c.default || c))
export const FormsPreviewFilePreview = () => import('../../components/forms/Preview/FilePreview.vue' /* webpackChunkName: "components/forms-preview-file-preview" */).then(c => wrapFunctional(c.default || c))
export const IconsRegisterNlIcvIcon = () => import('../../components/icons/RegisterNl/IcvIcon.vue' /* webpackChunkName: "components/icons-register-nl-icv-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsRioNegroChatIcon = () => import('../../components/icons/RioNegro/ChatIcon.vue' /* webpackChunkName: "components/icons-rio-negro-chat-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsRioNegroContactoIcon = () => import('../../components/icons/RioNegro/ContactoIcon.vue' /* webpackChunkName: "components/icons-rio-negro-contacto-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsRioNegroTelefonosIcon = () => import('../../components/icons/RioNegro/TelefonosIcon.vue' /* webpackChunkName: "components/icons-rio-negro-telefonos-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsCitasAppointmentIcon = () => import('../../components/icons/citas/AppointmentIcon.vue' /* webpackChunkName: "components/icons-citas-appointment-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsCitasCalendarioIcon = () => import('../../components/icons/citas/CalendarioIcon.vue' /* webpackChunkName: "components/icons-citas-calendario-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsCitasConfigCitasIcon = () => import('../../components/icons/citas/ConfigCitasIcon.vue' /* webpackChunkName: "components/icons-citas-config-citas-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsCitasDatosGeneralesIcon = () => import('../../components/icons/citas/DatosGeneralesIcon.vue' /* webpackChunkName: "components/icons-citas-datos-generales-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsCitasDirectorioOficinas = () => import('../../components/icons/citas/DirectorioOficinas.vue' /* webpackChunkName: "components/icons-citas-directorio-oficinas" */).then(c => wrapFunctional(c.default || c))
export const IconsCitasEditBtnIcon = () => import('../../components/icons/citas/EditBtnIcon.vue' /* webpackChunkName: "components/icons-citas-edit-btn-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsCitasEscritoriosIcon = () => import('../../components/icons/citas/EscritoriosIcon.vue' /* webpackChunkName: "components/icons-citas-escritorios-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsCitasPersonIconOffice = () => import('../../components/icons/citas/PersonIconOffice.vue' /* webpackChunkName: "components/icons-citas-person-icon-office" */).then(c => wrapFunctional(c.default || c))
export const IconsCitasPersonalIcon = () => import('../../components/icons/citas/PersonalIcon.vue' /* webpackChunkName: "components/icons-citas-personal-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsDetailsAlertInformationIcon = () => import('../../components/icons/details/AlertInformationIcon.vue' /* webpackChunkName: "components/icons-details-alert-information-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsDetailsDataIcon = () => import('../../components/icons/details/DataIcon.vue' /* webpackChunkName: "components/icons-details-data-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsDetailsDocumentIcon = () => import('../../components/icons/details/DocumentIcon.vue' /* webpackChunkName: "components/icons-details-document-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsDetailsDownloadIcon = () => import('../../components/icons/details/DownloadIcon.vue' /* webpackChunkName: "components/icons-details-download-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsDetailsFileIcon = () => import('../../components/icons/details/FileIcon.vue' /* webpackChunkName: "components/icons-details-file-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsDetailsFileTypeIcon = () => import('../../components/icons/details/FileTypeIcon.vue' /* webpackChunkName: "components/icons-details-file-type-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsDetailsFolderIcon = () => import('../../components/icons/details/FolderIcon.vue' /* webpackChunkName: "components/icons-details-folder-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsDetailsFormIcon = () => import('../../components/icons/details/FormIcon.vue' /* webpackChunkName: "components/icons-details-form-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsDetailsNoteIcon = () => import('../../components/icons/details/NoteIcon.vue' /* webpackChunkName: "components/icons-details-note-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsDetailsSendIcon = () => import('../../components/icons/details/SendIcon.vue' /* webpackChunkName: "components/icons-details-send-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsDetailsTimeIcon = () => import('../../components/icons/details/TimeIcon.vue' /* webpackChunkName: "components/icons-details-time-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsEtapasConclusionIcon = () => import('../../components/icons/etapas/ConclusionIcon.vue' /* webpackChunkName: "components/icons-etapas-conclusion-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsEtapasEmisionIcon = () => import('../../components/icons/etapas/EmisionIcon.vue' /* webpackChunkName: "components/icons-etapas-emision-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsEtapasPreventionIcon = () => import('../../components/icons/etapas/PreventionIcon.vue' /* webpackChunkName: "components/icons-etapas-prevention-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsFormFillCitasIcon = () => import('../../components/icons/formFill/CitasIcon.vue' /* webpackChunkName: "components/icons-form-fill-citas-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsFormFillDictaminacionIcon = () => import('../../components/icons/formFill/DictaminacionIcon.vue' /* webpackChunkName: "components/icons-form-fill-dictaminacion-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsFormFillEmisionIcon = () => import('../../components/icons/formFill/EmisionIcon.vue' /* webpackChunkName: "components/icons-form-fill-emision-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsFormFillFinalDocIcon = () => import('../../components/icons/formFill/FinalDocIcon.vue' /* webpackChunkName: "components/icons-form-fill-final-doc-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsFormWithSectionsIcon = () => import('../../components/icons/formFill/FormWithSectionsIcon.vue' /* webpackChunkName: "components/icons-form-with-sections-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsFormWithStepsIcon = () => import('../../components/icons/formFill/FormWithStepsIcon.vue' /* webpackChunkName: "components/icons-form-with-steps-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsFormFillFormularioIcon = () => import('../../components/icons/formFill/FormularioIcon.vue' /* webpackChunkName: "components/icons-form-fill-formulario-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsFormFillInspectorIcon = () => import('../../components/icons/formFill/InspectorIcon.vue' /* webpackChunkName: "components/icons-form-fill-inspector-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsFormFillNotificatorIcon = () => import('../../components/icons/formFill/NotificatorIcon.vue' /* webpackChunkName: "components/icons-form-fill-notificator-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsFormFillPagosIcon = () => import('../../components/icons/formFill/PagosIcon.vue' /* webpackChunkName: "components/icons-form-fill-pagos-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsFormFillRevisionIcon = () => import('../../components/icons/formFill/RevisionIcon.vue' /* webpackChunkName: "components/icons-form-fill-revision-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsFormFillStagesIcon = () => import('../../components/icons/formFill/StagesIcon.vue' /* webpackChunkName: "components/icons-form-fill-stages-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsFormFillTypeStages = () => import('../../components/icons/formFill/TypeStages.vue' /* webpackChunkName: "components/icons-form-fill-type-stages" */).then(c => wrapFunctional(c.default || c))
export const IconsFormFillSubStagesIcon = () => import('../../components/icons/formFill/subStagesIcon.vue' /* webpackChunkName: "components/icons-form-fill-sub-stages-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsInboxDetailsIcon = () => import('../../components/icons/inbox/DetailsIcon.vue' /* webpackChunkName: "components/icons-inbox-details-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsInboxPrivateProcedure = () => import('../../components/icons/inbox/PrivateProcedure.vue' /* webpackChunkName: "components/icons-inbox-private-procedure" */).then(c => wrapFunctional(c.default || c))
export const IconsInspectorsAssignInbox = () => import('../../components/icons/inspectors/AssignInbox.vue' /* webpackChunkName: "components/icons-inspectors-assign-inbox" */).then(c => wrapFunctional(c.default || c))
export const IconsInspectorsCalendar = () => import('../../components/icons/inspectors/Calendar.vue' /* webpackChunkName: "components/icons-inspectors-calendar" */).then(c => wrapFunctional(c.default || c))
export const IconsInspectorsDireccion = () => import('../../components/icons/inspectors/Direccion.vue' /* webpackChunkName: "components/icons-inspectors-direccion" */).then(c => wrapFunctional(c.default || c))
export const IconsInspectorsInspectionsInbox = () => import('../../components/icons/inspectors/InspectionsInbox.vue' /* webpackChunkName: "components/icons-inspectors-inspections-inbox" */).then(c => wrapFunctional(c.default || c))
export const IconsInspectorsJefe = () => import('../../components/icons/inspectors/Jefe.vue' /* webpackChunkName: "components/icons-inspectors-jefe" */).then(c => wrapFunctional(c.default || c))
export const IconsModuleArrowTabsIcon = () => import('../../components/icons/module/ArrowTabsIcon.vue' /* webpackChunkName: "components/icons-module-arrow-tabs-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsModuleBrushIcon = () => import('../../components/icons/module/BrushIcon.vue' /* webpackChunkName: "components/icons-module-brush-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsModuleBuilderFile = () => import('../../components/icons/module/BuilderFile.vue' /* webpackChunkName: "components/icons-module-builder-file" */).then(c => wrapFunctional(c.default || c))
export const IconsModuleConfigModuleIcon = () => import('../../components/icons/module/ConfigModuleIcon.vue' /* webpackChunkName: "components/icons-module-config-module-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsModuleFileProcedureIcon = () => import('../../components/icons/module/FileProcedureIcon.vue' /* webpackChunkName: "components/icons-module-file-procedure-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsModuleFolderOpenIcon = () => import('../../components/icons/module/FolderOpenIcon.vue' /* webpackChunkName: "components/icons-module-folder-open-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsModuleInboxIcon = () => import('../../components/icons/module/InboxIcon.vue' /* webpackChunkName: "components/icons-module-inbox-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsModuleListIcon = () => import('../../components/icons/module/ListIcon.vue' /* webpackChunkName: "components/icons-module-list-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsPaymentsArrowTabsIcon = () => import('../../components/icons/payments/ArrowTabsIcon.vue' /* webpackChunkName: "components/icons-payments-arrow-tabs-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsPaymentsCardPayIcon = () => import('../../components/icons/payments/CardPayIcon.vue' /* webpackChunkName: "components/icons-payments-card-pay-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsPaymentsDashboardIcon = () => import('../../components/icons/payments/DashboardIcon.vue' /* webpackChunkName: "components/icons-payments-dashboard-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsPaymentsFolderOpenIcon = () => import('../../components/icons/payments/FolderOpenIcon.vue' /* webpackChunkName: "components/icons-payments-folder-open-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsPaymentsInboxIcon = () => import('../../components/icons/payments/InboxIcon.vue' /* webpackChunkName: "components/icons-payments-inbox-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsPaymentsLogoMercadoPagoBlanco = () => import('../../components/icons/payments/LogoMercadoPagoBlanco.vue' /* webpackChunkName: "components/icons-payments-logo-mercado-pago-blanco" */).then(c => wrapFunctional(c.default || c))
export const IconsPaymentsWalletIcon = () => import('../../components/icons/payments/WalletIcon.vue' /* webpackChunkName: "components/icons-payments-wallet-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsPlaylistPostAddIcon = () => import('../../components/icons/playlist/PostAddIcon.vue' /* webpackChunkName: "components/icons-playlist-post-add-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsPredialFolderIdIcon = () => import('../../components/icons/predial/FolderIdIcon.vue' /* webpackChunkName: "components/icons-predial-folder-id-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsPredialOrderPayIcon = () => import('../../components/icons/predial/OrderPayIcon.vue' /* webpackChunkName: "components/icons-predial-order-pay-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsPredialReceiptPayIcon = () => import('../../components/icons/predial/ReceiptPayIcon.vue' /* webpackChunkName: "components/icons-predial-receipt-pay-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsProceduresCargaArchivo = () => import('../../components/icons/procedures/CargaArchivo.vue' /* webpackChunkName: "components/icons-procedures-carga-archivo" */).then(c => wrapFunctional(c.default || c))
export const IconsProceduresCasillaVerificacion = () => import('../../components/icons/procedures/CasillaVerificacion.vue' /* webpackChunkName: "components/icons-procedures-casilla-verificacion" */).then(c => wrapFunctional(c.default || c))
export const IconsProceduresDestacados = () => import('../../components/icons/procedures/Destacados.vue' /* webpackChunkName: "components/icons-procedures-destacados" */).then(c => wrapFunctional(c.default || c))
export const IconsProceduresDocIcon = () => import('../../components/icons/procedures/DocIcon.vue' /* webpackChunkName: "components/icons-procedures-doc-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsProceduresFecha = () => import('../../components/icons/procedures/Fecha.vue' /* webpackChunkName: "components/icons-procedures-fecha" */).then(c => wrapFunctional(c.default || c))
export const IconsProceduresGrupoDePreguntas = () => import('../../components/icons/procedures/GrupoDePreguntas.vue' /* webpackChunkName: "components/icons-procedures-grupo-de-preguntas" */).then(c => wrapFunctional(c.default || c))
export const IconsProceduresLegos2 = () => import('../../components/icons/procedures/Legos2.vue' /* webpackChunkName: "components/icons-procedures-legos2" */).then(c => wrapFunctional(c.default || c))
export const IconsProceduresListaDesplegable = () => import('../../components/icons/procedures/ListaDesplegable.vue' /* webpackChunkName: "components/icons-procedures-lista-desplegable" */).then(c => wrapFunctional(c.default || c))
export const IconsProceduresNuevaSeccion = () => import('../../components/icons/procedures/NuevaSeccion.vue' /* webpackChunkName: "components/icons-procedures-nueva-seccion" */).then(c => wrapFunctional(c.default || c))
export const IconsProceduresOpcionMultiple = () => import('../../components/icons/procedures/OpcionMultiple.vue' /* webpackChunkName: "components/icons-procedures-opcion-multiple" */).then(c => wrapFunctional(c.default || c))
export const IconsProceduresParrafo = () => import('../../components/icons/procedures/Parrafo.vue' /* webpackChunkName: "components/icons-procedures-parrafo" */).then(c => wrapFunctional(c.default || c))
export const IconsProceduresRespuestaBreve = () => import('../../components/icons/procedures/RespuestaBreve.vue' /* webpackChunkName: "components/icons-procedures-respuesta-breve" */).then(c => wrapFunctional(c.default || c))
export const IconsProceduresSecciones = () => import('../../components/icons/procedures/Secciones.vue' /* webpackChunkName: "components/icons-procedures-secciones" */).then(c => wrapFunctional(c.default || c))
export const IconsProceduresTime = () => import('../../components/icons/procedures/Time.vue' /* webpackChunkName: "components/icons-procedures-time" */).then(c => wrapFunctional(c.default || c))
export const IconsProceduresIdCard = () => import('../../components/icons/procedures/id-card.vue' /* webpackChunkName: "components/icons-procedures-id-card" */).then(c => wrapFunctional(c.default || c))
export const IconsProceduresOpenBook = () => import('../../components/icons/procedures/open-book.vue' /* webpackChunkName: "components/icons-procedures-open-book" */).then(c => wrapFunctional(c.default || c))
export const IconsRionegroFlagGuideIcon = () => import('../../components/icons/rionegro/FlagGuideIcon.vue' /* webpackChunkName: "components/icons-rionegro-flag-guide-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSidebarBandejaEntradaIcon = () => import('../../components/icons/sidebar/BandejaEntradaIcon.vue' /* webpackChunkName: "components/icons-sidebar-bandeja-entrada-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSidebarCatalogosIcon = () => import('../../components/icons/sidebar/CatalogosIcon.vue' /* webpackChunkName: "components/icons-sidebar-catalogos-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSidebarConfiguracionIcon = () => import('../../components/icons/sidebar/ConfiguracionIcon.vue' /* webpackChunkName: "components/icons-sidebar-configuracion-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSidebarEditarAparienciaIcon = () => import('../../components/icons/sidebar/EditarAparienciaIcon.vue' /* webpackChunkName: "components/icons-sidebar-editar-apariencia-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSidebarEstadisticasIcon = () => import('../../components/icons/sidebar/EstadisticasIcon.vue' /* webpackChunkName: "components/icons-sidebar-estadisticas-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSidebarFilterIcon = () => import('../../components/icons/sidebar/FilterIcon.vue' /* webpackChunkName: "components/icons-sidebar-filter-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSidebarHerramientasIcon = () => import('../../components/icons/sidebar/HerramientasIcon.vue' /* webpackChunkName: "components/icons-sidebar-herramientas-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSidebarModuleIcon = () => import('../../components/icons/sidebar/ModuleIcon.vue' /* webpackChunkName: "components/icons-sidebar-module-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSidebarOSCityIcon = () => import('../../components/icons/sidebar/OSCityIcon.vue' /* webpackChunkName: "components/icons-sidebar-o-s-city-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSidebarTemplatesIcon = () => import('../../components/icons/sidebar/TemplatesIcon.vue' /* webpackChunkName: "components/icons-sidebar-templates-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSidebarUserIcon = () => import('../../components/icons/sidebar/UserIcon.vue' /* webpackChunkName: "components/icons-sidebar-user-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsTemplateBuilderBloques = () => import('../../components/icons/templateBuilder/Bloques.vue' /* webpackChunkName: "components/icons-template-builder-bloques" */).then(c => wrapFunctional(c.default || c))
export const IconsTemplateBuilderCatalogo = () => import('../../components/icons/templateBuilder/Catalogo.vue' /* webpackChunkName: "components/icons-template-builder-catalogo" */).then(c => wrapFunctional(c.default || c))
export const IconsTemplateBuilderDivisorDePregunta = () => import('../../components/icons/templateBuilder/DivisorDePregunta.vue' /* webpackChunkName: "components/icons-template-builder-divisor-de-pregunta" */).then(c => wrapFunctional(c.default || c))
export const IconsTemplateBuilderDosColumnas = () => import('../../components/icons/templateBuilder/DosColumnas.vue' /* webpackChunkName: "components/icons-template-builder-dos-columnas" */).then(c => wrapFunctional(c.default || c))
export const IconsTemplateBuilderEstructura = () => import('../../components/icons/templateBuilder/Estructura.vue' /* webpackChunkName: "components/icons-template-builder-estructura" */).then(c => wrapFunctional(c.default || c))
export const IconsTemplateBuilderImagen = () => import('../../components/icons/templateBuilder/Imagen.vue' /* webpackChunkName: "components/icons-template-builder-imagen" */).then(c => wrapFunctional(c.default || c))
export const IconsTemplateBuilderSeparador = () => import('../../components/icons/templateBuilder/Separador.vue' /* webpackChunkName: "components/icons-template-builder-separador" */).then(c => wrapFunctional(c.default || c))
export const IconsTemplateBuilderTexto = () => import('../../components/icons/templateBuilder/Texto.vue' /* webpackChunkName: "components/icons-template-builder-texto" */).then(c => wrapFunctional(c.default || c))
export const IconsTemplateBuilderTitular = () => import('../../components/icons/templateBuilder/Titular.vue' /* webpackChunkName: "components/icons-template-builder-titular" */).then(c => wrapFunctional(c.default || c))
export const IconsTemplateBuilderTresColumnas = () => import('../../components/icons/templateBuilder/TresColumnas.vue' /* webpackChunkName: "components/icons-template-builder-tres-columnas" */).then(c => wrapFunctional(c.default || c))
export const IconsTemplateBuilderUnaColumna = () => import('../../components/icons/templateBuilder/UnaColumna.vue' /* webpackChunkName: "components/icons-template-builder-una-columna" */).then(c => wrapFunctional(c.default || c))
export const IconsUsersSidebarIcon = () => import('../../components/icons/users/SidebarIcon.vue' /* webpackChunkName: "components/icons-users-sidebar-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsVerificationCodeHashIcon = () => import('../../components/icons/verification/CodeHashIcon.vue' /* webpackChunkName: "components/icons-verification-code-hash-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsVerificationHashIcon = () => import('../../components/icons/verification/HashIcon.vue' /* webpackChunkName: "components/icons-verification-hash-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsVerificationNoCodeIcon = () => import('../../components/icons/verification/NoCodeIcon.vue' /* webpackChunkName: "components/icons-verification-no-code-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsVerifierLogo = () => import('../../components/icons/verifier/VerifierLogo.vue' /* webpackChunkName: "components/icons-verifier-logo" */).then(c => wrapFunctional(c.default || c))
export const InspectorsConfigurationAddConceptInspectors = () => import('../../components/inspectors/configuration/AddConceptInspectors.vue' /* webpackChunkName: "components/inspectors-configuration-add-concept-inspectors" */).then(c => wrapFunctional(c.default || c))
export const InspectorsConfigurationConceptsInspectors = () => import('../../components/inspectors/configuration/ConceptsInspectors.vue' /* webpackChunkName: "components/inspectors-configuration-concepts-inspectors" */).then(c => wrapFunctional(c.default || c))
export const InspectorsConfigurationConceptsList = () => import('../../components/inspectors/configuration/ConceptsList.vue' /* webpackChunkName: "components/inspectors-configuration-concepts-list" */).then(c => wrapFunctional(c.default || c))
export const InspectorsConfigurationConfigInspectors = () => import('../../components/inspectors/configuration/ConfigInspectors.vue' /* webpackChunkName: "components/inspectors-configuration-config-inspectors" */).then(c => wrapFunctional(c.default || c))
export const InspectorsConfigurationUploadConcepts = () => import('../../components/inspectors/configuration/UploadConcepts.vue' /* webpackChunkName: "components/inspectors-configuration-upload-concepts" */).then(c => wrapFunctional(c.default || c))
export const OperatorsOutputDocumentsShowOutputDocuments = () => import('../../components/operators/OutputDocuments/ShowOutputDocuments.vue' /* webpackChunkName: "components/operators-output-documents-show-output-documents" */).then(c => wrapFunctional(c.default || c))
export const OperatorsOutputDocumentsSignOutputDocumentsSaved = () => import('../../components/operators/OutputDocuments/SignOutputDocumentsSaved.vue' /* webpackChunkName: "components/operators-output-documents-sign-output-documents-saved" */).then(c => wrapFunctional(c.default || c))
export const OperatorsOutputDocumentsUploadOutputDocuments = () => import('../../components/operators/OutputDocuments/UploadOutputDocuments.vue' /* webpackChunkName: "components/operators-output-documents-upload-output-documents" */).then(c => wrapFunctional(c.default || c))
export const OperatorsDetailsAddOperatorObservations = () => import('../../components/operators/details/AddOperatorObservations.vue' /* webpackChunkName: "components/operators-details-add-operator-observations" */).then(c => wrapFunctional(c.default || c))
export const OperatorsDetailsAsignacionEtapas = () => import('../../components/operators/details/AsignacionEtapas.vue' /* webpackChunkName: "components/operators-details-asignacion-etapas" */).then(c => wrapFunctional(c.default || c))
export const OperatorsDetailsAssignationTimeline = () => import('../../components/operators/details/AssignationTimeline.vue' /* webpackChunkName: "components/operators-details-assignation-timeline" */).then(c => wrapFunctional(c.default || c))
export const OperatorsDetailsDialogSubStage = () => import('../../components/operators/details/DialogSubStage.vue' /* webpackChunkName: "components/operators-details-dialog-sub-stage" */).then(c => wrapFunctional(c.default || c))
export const OperatorsDetailsDocuments = () => import('../../components/operators/details/Documents.vue' /* webpackChunkName: "components/operators-details-documents" */).then(c => wrapFunctional(c.default || c))
export const OperatorsDetailsDragFile = () => import('../../components/operators/details/DragFile.vue' /* webpackChunkName: "components/operators-details-drag-file" */).then(c => wrapFunctional(c.default || c))
export const OperatorsDetailsNewRequests = () => import('../../components/operators/details/NewRequests.vue' /* webpackChunkName: "components/operators-details-new-requests" */).then(c => wrapFunctional(c.default || c))
export const OperatorsDetailsNotas = () => import('../../components/operators/details/Notas.vue' /* webpackChunkName: "components/operators-details-notas" */).then(c => wrapFunctional(c.default || c))
export const OperatorsDetailsPayments = () => import('../../components/operators/details/Payments.vue' /* webpackChunkName: "components/operators-details-payments" */).then(c => wrapFunctional(c.default || c))
export const OperatorsDetailsTimeline = () => import('../../components/operators/details/Timeline.vue' /* webpackChunkName: "components/operators-details-timeline" */).then(c => wrapFunctional(c.default || c))
export const OperatorsDetailsTimelinePost = () => import('../../components/operators/details/TimelinePost.vue' /* webpackChunkName: "components/operators-details-timeline-post" */).then(c => wrapFunctional(c.default || c))
export const OperatorsDetailsUploadFile = () => import('../../components/operators/details/UploadFile.vue' /* webpackChunkName: "components/operators-details-upload-file" */).then(c => wrapFunctional(c.default || c))
export const OperatorsDetailsUserInfo = () => import('../../components/operators/details/UserInfo.vue' /* webpackChunkName: "components/operators-details-user-info" */).then(c => wrapFunctional(c.default || c))
export const PaymentsBancoMacro = () => import('../../components/payments/BancoMacro/BancoMacro.vue' /* webpackChunkName: "components/payments-banco-macro" */).then(c => wrapFunctional(c.default || c))
export const PaymentsBancoMacroDebin = () => import('../../components/payments/BancoMacro/Debin.vue' /* webpackChunkName: "components/payments-banco-macro-debin" */).then(c => wrapFunctional(c.default || c))
export const PaymentsBancoMacroFormulario = () => import('../../components/payments/BancoMacro/Formulario.vue' /* webpackChunkName: "components/payments-banco-macro-formulario" */).then(c => wrapFunctional(c.default || c))
export const PaymentsInboxCalendar = () => import('../../components/payments/Inbox/Calendar.vue' /* webpackChunkName: "components/payments-inbox-calendar" */).then(c => wrapFunctional(c.default || c))
export const PaymentsInboxCounters = () => import('../../components/payments/Inbox/Counters.vue' /* webpackChunkName: "components/payments-inbox-counters" */).then(c => wrapFunctional(c.default || c))
export const PaymentsInboxExportButton = () => import('../../components/payments/Inbox/ExportButton.vue' /* webpackChunkName: "components/payments-inbox-export-button" */).then(c => wrapFunctional(c.default || c))
export const PaymentsInboxFilters = () => import('../../components/payments/Inbox/Filters.vue' /* webpackChunkName: "components/payments-inbox-filters" */).then(c => wrapFunctional(c.default || c))
export const PaymentsOpenPayCardForm = () => import('../../components/payments/OpenPay/CardForm.vue' /* webpackChunkName: "components/payments-open-pay-card-form" */).then(c => wrapFunctional(c.default || c))
export const PaymentsConfigurationAddBancoMacro = () => import('../../components/payments/configuration/AddBancoMacro.vue' /* webpackChunkName: "components/payments-configuration-add-banco-macro" */).then(c => wrapFunctional(c.default || c))
export const PaymentsConfigurationAddMercadoPago = () => import('../../components/payments/configuration/AddMercadoPago.vue' /* webpackChunkName: "components/payments-configuration-add-mercado-pago" */).then(c => wrapFunctional(c.default || c))
export const PaymentsConfiguration = () => import('../../components/payments/configuration/PaymentsConfiguration.vue' /* webpackChunkName: "components/payments-configuration" */).then(c => wrapFunctional(c.default || c))
export const PlaylistEditorMenuBarPlaylist = () => import('../../components/playlist/Editor/MenuBarPlaylist.vue' /* webpackChunkName: "components/playlist-editor-menu-bar-playlist" */).then(c => wrapFunctional(c.default || c))
export const PlaylistEditorTextEditorPlaylist = () => import('../../components/playlist/Editor/TextEditorPlaylist.vue' /* webpackChunkName: "components/playlist-editor-text-editor-playlist" */).then(c => wrapFunctional(c.default || c))
export const UsersConfigurationActionsChangeEmailModal = () => import('../../components/Users/configuration/actions/ChangeEmailModal.vue' /* webpackChunkName: "components/users-configuration-actions-change-email-modal" */).then(c => wrapFunctional(c.default || c))
export const UsersConfigurationActionsChangePassModal = () => import('../../components/Users/configuration/actions/ChangePassModal.vue' /* webpackChunkName: "components/users-configuration-actions-change-pass-modal" */).then(c => wrapFunctional(c.default || c))
export const ExpedienteDetailsInfoProcedureColumn1 = () => import('../../components/expediente/details/infoTop/InfoProcedureColumn1.vue' /* webpackChunkName: "components/expediente-details-info-procedure-column1" */).then(c => wrapFunctional(c.default || c))
export const ExpedienteDetailsInfoProcedureColumn2 = () => import('../../components/expediente/details/infoTop/InfoProcedureColumn2.vue' /* webpackChunkName: "components/expediente-details-info-procedure-column2" */).then(c => wrapFunctional(c.default || c))
export const ExpedienteDetailsInfoProcedureColumn3 = () => import('../../components/expediente/details/infoTop/InfoProcedureColumn3.vue' /* webpackChunkName: "components/expediente-details-info-procedure-column3" */).then(c => wrapFunctional(c.default || c))
export const ExpedienteDetailsInfoTopPaymentInfo = () => import('../../components/expediente/details/infoTop/PaymentInfo.vue' /* webpackChunkName: "components/expediente-details-info-top-payment-info" */).then(c => wrapFunctional(c.default || c))
export const ExpedienteDetailsInfoTopPreventive = () => import('../../components/expediente/details/infoTop/Preventive.vue' /* webpackChunkName: "components/expediente-details-info-top-preventive" */).then(c => wrapFunctional(c.default || c))
export const ExpedienteDetailsInfoTopTimeAppoinment = () => import('../../components/expediente/details/infoTop/TimeAppoinment.vue' /* webpackChunkName: "components/expediente-details-info-top-time-appoinment" */).then(c => wrapFunctional(c.default || c))
export const FormsFormFillOperatorDataStagesContentStage = () => import('../../components/forms/FormFillOperator/DataStages/ContentStage.vue' /* webpackChunkName: "components/forms-form-fill-operator-data-stages-content-stage" */).then(c => wrapFunctional(c.default || c))
export const FormsFormFillOperatorDataStagesContentSubStage = () => import('../../components/forms/FormFillOperator/DataStages/ContentSubStage.vue' /* webpackChunkName: "components/forms-form-fill-operator-data-stages-content-sub-stage" */).then(c => wrapFunctional(c.default || c))
export const FormsFormFillOperatorDataStagesIconsStage = () => import('../../components/forms/FormFillOperator/DataStages/IconsStage.vue' /* webpackChunkName: "components/forms-form-fill-operator-data-stages-icons-stage" */).then(c => wrapFunctional(c.default || c))
export const FormsFormFillOperatorDiffComponentsProcedureAnwerInfo = () => import('../../components/forms/FormFillOperator/DiffComponents/ProcedureAnwerInfo.vue' /* webpackChunkName: "components/forms-form-fill-operator-diff-components-procedure-anwer-info" */).then(c => wrapFunctional(c.default || c))
export const FormsFormFillOperatorDiffComponentsProcedureFinalDocument = () => import('../../components/forms/FormFillOperator/DiffComponents/ProcedureFinalDocument.vue' /* webpackChunkName: "components/forms-form-fill-operator-diff-components-procedure-final-document" */).then(c => wrapFunctional(c.default || c))
export const FormsFormFillOperatorDiffComponentsProcedureForm = () => import('../../components/forms/FormFillOperator/DiffComponents/ProcedureForm.vue' /* webpackChunkName: "components/forms-form-fill-operator-diff-components-procedure-form" */).then(c => wrapFunctional(c.default || c))
export const FormsFormFillOperatorDiffComponentsProcedureSettings = () => import('../../components/forms/FormFillOperator/DiffComponents/ProcedureSettings.vue' /* webpackChunkName: "components/forms-form-fill-operator-diff-components-procedure-settings" */).then(c => wrapFunctional(c.default || c))
export const FormsFormFillOperatorDiffComponentsProcedureStages = () => import('../../components/forms/FormFillOperator/DiffComponents/ProcedureStages.vue' /* webpackChunkName: "components/forms-form-fill-operator-diff-components-procedure-stages" */).then(c => wrapFunctional(c.default || c))
export const FormsFormFillOperatorDiffComponentsQuestionInfo = () => import('../../components/forms/FormFillOperator/DiffComponents/QuestionInfo.vue' /* webpackChunkName: "components/forms-form-fill-operator-diff-components-question-info" */).then(c => wrapFunctional(c.default || c))
export const FormsFormFillOperatorEditorMenuBar = () => import('../../components/forms/FormFillOperator/Editor/MenuBar.vue' /* webpackChunkName: "components/forms-form-fill-operator-editor-menu-bar" */).then(c => wrapFunctional(c.default || c))
export const FormsFormFillOperatorEditorTextEditor = () => import('../../components/forms/FormFillOperator/Editor/TextEditor.vue' /* webpackChunkName: "components/forms-form-fill-operator-editor-text-editor" */).then(c => wrapFunctional(c.default || c))
export const PaymentsConfigurationPaymentConceptsList = () => import('../../components/payments/configuration/PaymentConcepts/PaymentConceptsList.vue' /* webpackChunkName: "components/payments-configuration-payment-concepts-list" */).then(c => wrapFunctional(c.default || c))
export const PaymentsConfigurationPaymentConceptsUploadConcepts = () => import('../../components/payments/configuration/PaymentConcepts/UploadConcepts.vue' /* webpackChunkName: "components/payments-configuration-payment-concepts-upload-concepts" */).then(c => wrapFunctional(c.default || c))
export const PaymentsConfigurationPaymentMethodsList = () => import('../../components/payments/configuration/PaymentMethods/PaymentMethodsList.vue' /* webpackChunkName: "components/payments-configuration-payment-methods-list" */).then(c => wrapFunctional(c.default || c))
export const PaymentsConfigurationPaymentMethodsListItem = () => import('../../components/payments/configuration/PaymentMethods/PaymentMethodsListItem.vue' /* webpackChunkName: "components/payments-configuration-payment-methods-list-item" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
